@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Overpass', sans-serif;
}

.custom-container-style {
  max-width: 90%;
}

.equalHeight {
  height: 100%;
}

@media (max-width: 575px) {
  .custom-container-style {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}

.custom-container-style-2 {
  max-width: 78%;
}

.custom-container-style-3 {
  min-width: 85vw;
}

@media (min-width: 576px) {
  .custom-container-style-3 {
    min-width: 540px;
  }
}

@media (min-width: 768px) {
  .custom-container-style-3 {
    min-width: 720px;
  }
}

@media (min-width: 992px) {
  .custom-container-style-3 {
    min-width: 960px;
  }
}

@media (min-width: 1200px) {
  .custom-container-style-3 {
    min-width: 1140px;
  }
}

@media (min-width: 1440px) {
  .custom-container-style-3 {
    min-width: 1440px;
  }
}

.custom-container-position-1 {
  position: relative;
  transform: translate3d(-50%, 0, 0);
  left: 50%;
}

.custom-bg-grey-1 {
  background: #747473 !important;
}

.custom-margin-top {
  margin-top: 170px;
}

@media (min-width: 768px) {
  .custom-margin-top {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .custom-margin-top {
    margin-top: -80px;
  }
}

.custom-page-wrapper {
  padding-left: 6.4vw;
  padding-right: 6.4vw;
  position: relative;
  z-index: 1
}

.custom-font-secondary {
  font-family: 'Overpass', sans-serif !important;
}

.custom-font-tertiary {
  font-family: 'PT Serif', serif !important;
}

.custom-btn-style-1 {
  position: relative;
  border-radius: 0;
  left: 5px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.custom-btn-style-1:before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border: 1px solid #CCC;
  transition: ease all 300ms;
}

.custom-btn-style-1:hover:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom-btn-style-1.custom-btn-style-1-right {
  left: auto;
  right: 5px;
}

.custom-box-shadow-1 {
  box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.3);
}

.custom-stroke-text-effect-1 {
  -webkit-text-fill-color: #FFF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #CCC;
  white-space: nowrap;
}

.custom-big-font-size-1 {
  font-size: 147.2px;
  font-size: 9.2rem;
}

.custom-big-font-size-2 {
  font-size: 187.2px;
  font-size: 11.7rem;
}

.custom-big-font-size-3 {
  font-size: 115.2px;
  font-size: 7.2rem;
}

.custom-img-thumbnail-style-1:before {
  border-radius: 0 !important;
}

.custom-ls-1 {
  letter-spacing: 4px;
}

.custom-link-effect-1 {
  position: relative;
  padding-bottom: 4px;
}

.custom-link-effect-1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  border-bottom: 2px solid #CCC;
  transition: ease width 300ms;
}

.text-shadow-dark {
  text-shadow: 2px 2px 12px #000000b3;
}

.custom-link-effect-1:hover:after, .custom-link-effect-1.active:after {
  width: 100%;
}

@keyframes customFadeFlipAnim {
  0% {
    opacity: 0;
    transform: translateY(-20px) perspective(600px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) perspective(600px) rotateX(0);
  }
}

.customFadeFlipAnim {
  animation-name: customFadeFlipAnim;
}

/*
* Header
*/
html.sticky-header-active #header .header-body {
  box-shadow: none;
}

#header .header-nav-main nav > ul > li .dropdown-menu {
  border-radius: 0 !important;
}

@media (min-width: 992px) {
  #header .header-nav {
    flex: 0 auto;
  }
  #header .header-nav.header-nav-line.header-nav-bottom-line nav > ul > li > a:before,
  #header .header-nav.header-nav-line.header-nav-bottom-line nav > ul > li:hover > a:before {
    transform: none;
  }
  #header .header-nav-main nav > ul > li > a {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Overpass', sans-serif;
    letter-spacing: 1px;
    text-transform: none;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
    font-size: 14px;
    font-family: 'Overpass', sans-serif;
  }
}

@media (max-width: 991px) {
  #header .header-container {
    padding: 0 6.5vw;
  }
  #header.header-transparent .header-nav-main nav {
    padding: 0 6.4vw !important;
  }
  html.sticky-header-active #header.header-transparent .header-nav-main nav {
    padding: 0 6.4vw !important;
  }
}


/*
* Slider
*/
.custom-slider-background {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 6.4vw;
  right: 6.4vw;
  bottom: 0;
  margin-top: 103px;
  opacity: 1;
  transition: ease box-shadow 300ms;
  overflow: hidden
}

.custom-slider-background .custom-slider-background-image-stage-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.custom-slider-background .custom-slider-background-image-stage-outer .custom-slider-background-image-stage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-slider-background .custom-slider-background-image-stage-outer .custom-slider-background-image-stage .custom-slider-background-image-item {
  width: 100%;
  height: 100%;
}

.custom-slider-background.show {
  opacity: 1;
}

.custom-slider-background.show .custom-slider-background-image-stage-outer .custom-slider-background-image-stage {
  transition: ease transform 1s;
}

@media (max-width: 575px) {
  .custom-slider-background .custom-slider-background-image-stage-outer {
    width: 100%;
  }
  .custom-slider-background .custom-slider-background-image-stage-outer:nth-child(2) {
    display: none;
  }
}

#slider .owl-nav {
  transition: ease opacity 1s;
}

#slider .owl-nav.hide {
  opacity: 0;
  visibility: hidden;
}

#slider .owl-nav.show {
  opacity: 1;
  visibility: visible;
}

.has-sticky-header-transform .custom-slider-background {
  transform: translate3d(0, 136px, 0);
}

.has-sticky-header-transform + #footer {
  margin-top: -33px !important;
}

/*
* Breadcrumb
*/
.custom-breadcrumb-style-1.breadcrumb > li + li:before {
  content: "\f054";
  font-size: 14.4px;
  font-size: 0.9rem;
  padding-right: 10px;
  padding-left: 5px;
}

/*
* Card
*/
.custom-card-style-1 {
  transition: ease background-color 300ms;
}

.custom-card-style-1:hover {
  background-color: #CCC;
}

.custom-card-style-1:hover p {
  opacity: 0.8;
}

/*
* Owl Carousel
*/
@media (max-width: 767px) {
  .custom-dots-position .owl-dots {
    top: calc(50% + 275px) !important;
    transform: translate3d(0px, 0, 0px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .custom-dots-position .owl-dots {
    top: calc(50% + 225px) !important;
    transform: translate3d(0px, 0, 0px) !important;
  }
}

.custom-dots-style-1 .owl-dots {
  margin-top: 20px !important;
}

.custom-dots-style-1 .owl-dots .owl-dot span {
  background: transparent;
  border: 1px solid #CCC;
  width: 12px;
  height: 12px;
}

.custom-dots-style-1 .owl-dots .owl-dot:hover span, .custom-dots-style-1 .owl-dots .owl-dot.active span {
  background: #CCC;
}

.custom-dots-style-1.custom-dots-style-1-light .owl-dots .owl-dot span {
  border-color: #FFF;
}

.custom-dots-style-1.custom-dots-style-1-light .owl-dots .owl-dot:hover span, .custom-dots-style-1.custom-dots-style-1-light .owl-dots .owl-dot.active span {
  background: #FFF !important;
}

.custom-hide-prev-nav .owl-nav .owl-prev {
  display: none !important;
}

.custom-nav-grey.owl-carousel.nav-style-1 .owl-nav .owl-prev,
.custom-nav-grey.owl-carousel.nav-style-1 .owl-nav .owl-next {
  color: #aaaaaa !important;
}

/*
* Custom Section
*/
.custom-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-section .custom-section-left-content,
.custom-section .custom-section-right-content {
  display: none;
}

.custom-section .custom-section-middle-content {
  width: 100%;
  margin: 0 -70px;
  z-index: 1;
}

@media (min-width: 768px) {
  .custom-section .custom-section-left-content,
  .custom-section .custom-section-right-content {
    display: block;
    width: 34vw;
  }
  .custom-section .custom-section-middle-content {
    width: 50vw;
  }
}

@media (min-width: 992px) {
  .custom-section .custom-section-left-content,
  .custom-section .custom-section-right-content {
    width: 34vw;
  }
  .custom-section .custom-section-middle-content {
    width: 40vw;
  }
}

/*
* Custom Heading Style
*/
.custom-heading-1 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #CCC;
  white-space: nowrap;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 1px;
}

.custom-text-pos-3 {
  left: -10%;
  top: 45%;
}

.custom-heading-style-1 {
  position: relative;
  color: #FFF;
}

.custom-heading-style-1 .custom-heading-style-1-step {
  position: absolute;
  font-size: 4.5em;
  font-weight: 900;
  font-family: 'Overpass', sans-serif;
  top: 50%;
  left: 0;
  opacity: 0.1;
  transform: translate3d(0, -50%, 0);
  z-index: 0;
}

.custom-heading-style-1 .custom-heading-style-1-text {
  position: relative;
  letter-spacing: 4.5px;
  z-index: 1;
}

/*
* Testimonial
*/
.custom-testimonial-quote.testimonial.testimonial-with-quotes blockquote:before {
  content: '\f10d';
  font-family: "Font Awesome 6 Sharp";
  font-weight: 900;
  font-size: 22.4px;
  font-size: 1.4rem;
  color: #FFF;
}

/*
* Page Header
*/
.custom-page-header-1 {
  padding: 0 6.4vw !important;
}

.title-bg {
  background-position: bottom;
}

.custom-page-header-1 .custom-page-header-1-wrapper {
  transform: translate3d(0, 103px, 0);
  transition: ease box-shadow 300ms;
}

/*
* Load More
*/
.load-more-loader {
  display: none;
}

/*
* Sort Navigation
*/
.custom-nav-filter > li > a {
  color: #212121 !important;
  background: transparent !important;
  border-radius: 0 !important;
  padding: 0 4px;
  font-weight: 600;
  font-size: 14.4px;
  font-size: 0.9rem;
}

.custom-nav-filter > li.active > a {
  border-bottom: 1px solid #CCC !important;
}

.custom-nav-filter > li + li {
  margin-left: 15px;
}

/*
* Contact Form
*/
.custom-form-style-1 .form-control {
  height: calc(1.8em + 1.85rem + 2px);
  padding: 15.6px 20px;
  padding: 0.975rem 1.25rem;
  background: #f7f7f7;
  border-color: #f7f7f7;
  line-height: 1.5 !important;
}

.custom-form-style-1 .form-control:focus {
  border-color: #CCC;
}

.custom-form-style-1 .form-control::-webkit-input-placeholder {
  color: #a9a9a9;
}

.custom-form-style-1 .form-control::-moz-placeholder {
  color: #a9a9a9;
}

.custom-form-style-1 .form-control:-ms-input-placeholder {
  color: #a9a9a9;
}

.custom-form-style-1 select {
  color: #495057;
}

.custom-form-style-1 select option {
  color: #a9a9a9;
}

.custom-form-style-1 select option[selected] {
  color: #a9a9a9;
}

.custom-form-style-1 textarea.form-control {
  height: auto;
}

/*
* Footer
*/
#footer p, #footer li, #footer span, #footer a:not(.btn):not(.no-footer-css) {
  color: #999;
}

#footer .footer-copyright p {
  color: #999;
}

::-moz-selection {
  color: #FFF;
  background: #151515;
}

::selection {
  color: #FFF;
  background: #151515;
}

:root {
  --primary: #151515;
  --secondary: #e4002c;
  --tertiary: #8a3636;
}

.main > section:first-child {
  --accent-color: rgb(255 255 255 / 4%)
}

.road-large {
  --road-height: 60px;
  --road-dash-size: 77px;
  --road-dash-space: 20px;
  --road-lane-size: 18px;
  --road-outline-size: 22px;
  --road-angle: 21deg;
}

.road-small {
  --road-height: 30px;
  --road-dash-size: 35px;
  --road-dash-space: 10px;
  --road-lane-size: 10px;
  --road-outline-size: 10px;
  --road-angle: 21deg;
}

a {
  color: #151515;
}

a:hover {
  color: #373737;
}

a:focus {
  color: #373737;
}

a:active {
  color: #1d1d1d;
}

html .text-color-primary,
html .text-primary {
  color: #151515 !important;
}

html .text-color-hover-primary:hover,
html .text-hover-primary:hover {
  color: #151515 !important;
}

html .text-color-secondary,
html .text-secondary {
  color: #e4002c !important;
}

html .text-color-hover-secondary:hover,
html .text-hover-secondary:hover {
  color: #e4002c !important;
}

html .text-color-tertiary,
html .text-tertiary {
  color: #8a3636 !important;
}

html .text-color-hover-tertiary:hover,
html .text-hover-tertiary:hover {
  color: #8a3636 !important;
}

html .text-color-dark,
html .text-dark {
  color: #212529 !important;
}

html .text-color-hover-dark:hover,
html .text-hover-dark:hover {
  color: #212529 !important;
}

html .text-color-light,
html .text-light {
  color: #FFF !important;
}

html .text-color-hover-light:hover,
html .text-hover-light:hover {
  color: #FFF !important;
}

html .svg-fill-color-primary {
  fill: #151515 !important;
}

html .svg-fill-color-primary svg path,
html .svg-fill-color-primary svg rect,
html .svg-fill-color-primary svg line,
html .svg-fill-color-primary svg polyline,
html .svg-fill-color-primary svg polygon {
  fill: #151515 !important;
}

html .svg-fill-color-hover-primary:hover {
  fill: #151515 !important;
}

html .svg-fill-color-hover-primary svg:hover path,
html .svg-fill-color-hover-primary svg:hover rect,
html .svg-fill-color-hover-primary svg:hover line,
html .svg-fill-color-hover-primary svg:hover polyline,
html .svg-fill-color-hover-primary svg:hover polygon {
  fill: #151515 !important;
}

html .svg-stroke-color-primary {
  stroke: #151515 !important;
}

html .svg-stroke-color-primary svg path,
html .svg-stroke-color-primary svg rect,
html .svg-stroke-color-primary svg line,
html .svg-stroke-color-primary svg polyline,
html .svg-stroke-color-primary svg polygon {
  stroke: #151515 !important;
}

html .svg-stroke-color-hover-primary:hover {
  stroke: #151515 !important;
}

html .svg-stroke-color-hover-primary svg:hover path,
html .svg-stroke-color-hover-primary svg:hover rect,
html .svg-stroke-color-hover-primary svg:hover line,
html .svg-stroke-color-hover-primary svg:hover polyline,
html .svg-stroke-color-hover-primary svg:hover polygon {
  stroke: #151515 !important;
}

html .svg-fill-color-secondary {
  fill: #e4002c !important;
}

html .svg-fill-color-secondary svg path,
html .svg-fill-color-secondary svg rect,
html .svg-fill-color-secondary svg line,
html .svg-fill-color-secondary svg polyline,
html .svg-fill-color-secondary svg polygon {
  fill: #e4002c !important;
}

html .svg-fill-color-hover-secondary:hover {
  fill: #e4002c !important;
}

html .svg-fill-color-hover-secondary svg:hover path,
html .svg-fill-color-hover-secondary svg:hover rect,
html .svg-fill-color-hover-secondary svg:hover line,
html .svg-fill-color-hover-secondary svg:hover polyline,
html .svg-fill-color-hover-secondary svg:hover polygon {
  fill: #e4002c !important;
}

html .svg-stroke-color-secondary {
  stroke: #e4002c !important;
}

html .svg-stroke-color-secondary svg path,
html .svg-stroke-color-secondary svg rect,
html .svg-stroke-color-secondary svg line,
html .svg-stroke-color-secondary svg polyline,
html .svg-stroke-color-secondary svg polygon {
  stroke: #e4002c !important;
}

html .svg-stroke-color-hover-secondary:hover {
  stroke: #e4002c !important;
}

html .svg-stroke-color-hover-secondary svg:hover path,
html .svg-stroke-color-hover-secondary svg:hover rect,
html .svg-stroke-color-hover-secondary svg:hover line,
html .svg-stroke-color-hover-secondary svg:hover polyline,
html .svg-stroke-color-hover-secondary svg:hover polygon {
  stroke: #e4002c !important;
}

html .svg-fill-color-tertiary {
  fill: #8a3636 !important;
}

html .svg-fill-color-tertiary svg path,
html .svg-fill-color-tertiary svg rect,
html .svg-fill-color-tertiary svg line,
html .svg-fill-color-tertiary svg polyline,
html .svg-fill-color-tertiary svg polygon {
  fill: #8a3636 !important;
}

html .svg-fill-color-hover-tertiary:hover {
  fill: #8a3636 !important;
}

html .svg-fill-color-hover-tertiary svg:hover path,
html .svg-fill-color-hover-tertiary svg:hover rect,
html .svg-fill-color-hover-tertiary svg:hover line,
html .svg-fill-color-hover-tertiary svg:hover polyline,
html .svg-fill-color-hover-tertiary svg:hover polygon {
  fill: #8a3636 !important;
}

html .svg-stroke-color-tertiary {
  stroke: #8a3636 !important;
}

html .svg-stroke-color-tertiary svg path,
html .svg-stroke-color-tertiary svg rect,
html .svg-stroke-color-tertiary svg line,
html .svg-stroke-color-tertiary svg polyline,
html .svg-stroke-color-tertiary svg polygon {
  stroke: #8a3636 !important;
}

html .svg-stroke-color-hover-tertiary:hover {
  stroke: #8a3636 !important;
}

html .svg-stroke-color-hover-tertiary svg:hover path,
html .svg-stroke-color-hover-tertiary svg:hover rect,
html .svg-stroke-color-hover-tertiary svg:hover line,
html .svg-stroke-color-hover-tertiary svg:hover polyline,
html .svg-stroke-color-hover-tertiary svg:hover polygon {
  stroke: #8a3636 !important;
}


html .svg-fill-color-dark {
  fill: #212529 !important;
}

html .svg-fill-color-dark svg path,
html .svg-fill-color-dark svg rect,
html .svg-fill-color-dark svg line,
html .svg-fill-color-dark svg polyline,
html .svg-fill-color-dark svg polygon {
  fill: #212529 !important;
}

html .svg-fill-color-hover-dark:hover {
  fill: #212529 !important;
}

html .svg-fill-color-hover-dark svg:hover path,
html .svg-fill-color-hover-dark svg:hover rect,
html .svg-fill-color-hover-dark svg:hover line,
html .svg-fill-color-hover-dark svg:hover polyline,
html .svg-fill-color-hover-dark svg:hover polygon {
  fill: #212529 !important;
}

html .svg-stroke-color-dark {
  stroke: #212529 !important;
}

html .svg-stroke-color-dark svg path,
html .svg-stroke-color-dark svg rect,
html .svg-stroke-color-dark svg line,
html .svg-stroke-color-dark svg polyline,
html .svg-stroke-color-dark svg polygon {
  stroke: #212529 !important;
}

html .svg-stroke-color-hover-dark:hover {
  stroke: #212529 !important;
}

html .svg-stroke-color-hover-dark svg:hover path,
html .svg-stroke-color-hover-dark svg:hover rect,
html .svg-stroke-color-hover-dark svg:hover line,
html .svg-stroke-color-hover-dark svg:hover polyline,
html .svg-stroke-color-hover-dark svg:hover polygon {
  stroke: #212529 !important;
}

html .svg-fill-color-light {
  fill: #FFF !important;
}

html .svg-fill-color-light svg path,
html .svg-fill-color-light svg rect,
html .svg-fill-color-light svg line,
html .svg-fill-color-light svg polyline,
html .svg-fill-color-light svg polygon {
  fill: #FFF !important;
}

html .svg-fill-color-hover-light:hover {
  fill: #FFF !important;
}

html .svg-fill-color-hover-light svg:hover path,
html .svg-fill-color-hover-light svg:hover rect,
html .svg-fill-color-hover-light svg:hover line,
html .svg-fill-color-hover-light svg:hover polyline,
html .svg-fill-color-hover-light svg:hover polygon {
  fill: #FFF !important;
}

html .svg-stroke-color-light {
  stroke: #FFF !important;
}

html .svg-stroke-color-light svg path,
html .svg-stroke-color-light svg rect,
html .svg-stroke-color-light svg line,
html .svg-stroke-color-light svg polyline,
html .svg-stroke-color-light svg polygon {
  stroke: #FFF !important;
}

html .svg-stroke-color-hover-light:hover {
  stroke: #FFF !important;
}

html .svg-stroke-color-hover-light svg:hover path,
html .svg-stroke-color-hover-light svg:hover rect,
html .svg-stroke-color-hover-light svg:hover line,
html .svg-stroke-color-hover-light svg:hover polyline,
html .svg-stroke-color-hover-light svg:hover polygon {
  stroke: #FFF !important;
}

.svg-animation-effect-1-hover:hover svg path,
.svg-animation-effect-1-hover:hover svg polygon,
.svg-animation-effect-1-hover:hover svg polyline,
.svg-animation-effect-1-hover:hover svg rect {
  stroke: #151515;
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg rect {
  stroke: #151515;
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg rect {
  stroke: #e4002c;
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg rect {
  stroke: #8a3636;
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg rect {
  stroke: #212529;
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg rect {
  stroke: #FFF;
}

.gradient-text-color {
  color: #151515;
  background: linear-gradient(to bottom right, #151515, #e4002c);
  background-image: linear-gradient(to right, #151515, #e4002c);
}

html .bg-color-primary,
html .bg-primary {
  background-color: #151515 !important;
}

html .bg-color-hover-primary:hover,
html .bg-hover-primary:hover {
  background-color: #151515 !important;
}

html .bg-color-after-primary:after {
  background-color: #151515 !important;
}

html .bg-color-hover-after-primary:after:hover {
  background-color: #151515 !important;
}

html .bg-color-before-primary:before {
  background-color: #151515 !important;
}

html .bg-color-hover-before-primary:before:hover {
  background-color: #151515 !important;
}

html .bg-color-secondary,
html .bg-secondary {
  background-color: #e4002c !important;
}

html .bg-color-hover-secondary:hover,
html .bg-hover-secondary:hover {
  background-color: #e4002c !important;
}

html .bg-color-after-secondary:after {
  background-color: #e4002c !important;
}

html .bg-color-hover-after-secondary:after:hover {
  background-color: #e4002c !important;
}

html .bg-color-before-secondary:before {
  background-color: #e4002c !important;
}

html .bg-color-hover-before-secondary:before:hover {
  background-color: #e4002c !important;
}

html .bg-color-tertiary,
html .bg-tertiary {
  background-color: #8a3636 !important;
}

html .bg-color-hover-tertiary:hover,
html .bg-hover-tertiary:hover {
  background-color: #8a3636 !important;
}

html .bg-color-after-tertiary:after {
  background-color: #8a3636 !important;
}

html .bg-color-hover-after-tertiary:after:hover {
  background-color: #8a3636 !important;
}

html .bg-color-before-tertiary:before {
  background-color: #8a3636 !important;
}

html .bg-color-hover-before-tertiary:before:hover {
  background-color: #8a3636 !important;
}

html .bg-color-dark,
html .bg-dark {
  background-color: #212529 !important;
}

html .bg-color-hover-dark:hover,
html .bg-hover-dark:hover {
  background-color: #212529 !important;
}

html .bg-color-after-dark:after {
  background-color: #212529 !important;
}

html .bg-color-hover-after-dark:after:hover {
  background-color: #212529 !important;
}

html .bg-color-before-dark:before {
  background-color: #212529 !important;
}

html .bg-color-hover-before-dark:before:hover {
  background-color: #212529 !important;
}

html .bg-color-light,
html .bg-light {
  background-color: #FFF !important;
}

html .bg-color-hover-light:hover,
html .bg-hover-light:hover {
  background-color: #FFF !important;
}

html .bg-color-after-light:after {
  background-color: #FFF !important;
}

html .bg-color-hover-after-light:after:hover {
  background-color: #FFF !important;
}

html .bg-color-before-light:before {
  background-color: #FFF !important;
}

html .bg-color-hover-before-light:before:hover {
  background-color: #FFF !important;
}

.bg-gradient {
  background-color: #151515 !important;
  background-image: linear-gradient(to right, #151515 0%, #e4002c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151515', endColorstr='#e4002c', GradientType=1);
}

.bg-gradient-to-top {
  background-color: #151515 !important;
  background-image: linear-gradient(to top, #151515 0%, #e4002c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151515', endColorstr='#e4002c', GradientType=1);
}

.bg-gradient-to-bottom {
  background-color: #151515 !important;
  background-image: linear-gradient(to bottom, #151515 0%, #e4002c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151515', endColorstr='#e4002c', GradientType=1);
}

/* Color Transition */
@keyframes colorTransition {
  0% {
    background-color: #151515;
  }

  33% {
    background-color: #e4002c;
  }

  66% {
    background-color: #8a3636;
  }

  100% {
    background-color: #383f48;
  }
}

html .border-color-primary {
  border-color: #151515 !important;
}

html .border-color-hover-primary:hover {
  border-color: #151515 !important;
}

html .border-color-secondary {
  border-color: #e4002c !important;
}

html .border-color-hover-secondary:hover {
  border-color: #e4002c !important;
}

html .border-color-tertiary {
  border-color: #8a3636 !important;
}

html .border-color-hover-tertiary:hover {
  border-color: #8a3636 !important;
}

html .border-color-dark {
  border-color: #212529 !important;
}

html .border-color-hover-dark:hover {
  border-color: #212529 !important;
}

html .border-color-light {
  border-color: #FFF !important;
}

html .border-color-hover-light:hover {
  border-color: #FFF !important;
}

.alternative-font {
  color: #151515;
}

html .box-shadow-1-primary:before {
  box-shadow: 0 30px 90px #151515 !important;
}

html .box-shadow-1-secondary:before {
  box-shadow: 0 30px 90px #e4002c !important;
}

html .box-shadow-1-tertiary:before {
  box-shadow: 0 30px 90px #8a3636 !important;
}

html .box-shadow-1-dark:before {
  box-shadow: 0 30px 90px #212529 !important;
}

html .box-shadow-1-light:before {
  box-shadow: 0 30px 90px #FFF !important;
}

html .blockquote-primary {
  border-color: #151515 !important;
}

html .blockquote-secondary {
  border-color: #e4002c !important;
}

html .blockquote-tertiary {
  border-color: #8a3636 !important;
}

html .blockquote-dark {
  border-color: #212529 !important;
}

html .blockquote-light {
  border-color: #FFF !important;
}

p.drop-caps:first-letter {
  color: #151515;
}

p.drop-caps.drop-caps-style-2:first-letter {
  background-color: #151515;
}

html .nav-color-primary nav > ul > li > a {
  color: #151515 !important;
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #151515 !important;
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #151515 !important;
}

html .nav-color-secondary nav > ul > li > a {
  color: #e4002c !important;
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #e4002c !important;
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #e4002c !important;
}

html .nav-color-tertiary nav > ul > li > a {
  color: #8a3636 !important;
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #8a3636 !important;
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #8a3636 !important;
}

html .nav-color-dark nav > ul > li > a {
  color: #212529 !important;
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #212529 !important;
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #212529 !important;
}

html .nav-color-light nav > ul > li > a {
  color: #FFF !important;
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #FFF !important;
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #FFF !important;
}

.nav-pills > li.active > a,
.nav-pills .nav-link.active {
  background-color: #151515;
}

.nav-pills > li.active > a:hover,
.nav-pills .nav-link.active:hover,
.nav-pills > li.active > a:focus,
.nav-pills .nav-link.active:focus {
  background-color: #151515;
}

.nav-active-style-1 > li > a:hover,
.nav-active-style-1 > li > a:focus,
.nav-active-style-1 > li > a.active {
  border-bottom-color: #151515;
}

html .nav-pills-primary a {
  color: #151515;
}

html .nav-pills-primary a:hover {
  color: #373737;
}

html .nav-pills-primary a:focus {
  color: #373737;
}

html .nav-pills-primary a:active {
  color: #1d1d1d;
}

html .nav-pills-primary .nav-link.active,
html .nav-pills-primary > li.active > a {
  background-color: #151515;
}

html .nav-pills-primary .nav-link.active:hover,
html .nav-pills-primary > li.active > a:hover,
html .nav-pills-primary .nav-link.active:focus,
html .nav-pills-primary > li.active > a:focus {
  background-color: #151515;
}

html .nav-pills-secondary a {
  color: #e4002c;
}

html .nav-pills-secondary a:hover {
  color: #e7766f;
}

html .nav-pills-secondary a:focus {
  color: #e7766f;
}

html .nav-pills-secondary a:active {
  color: #df4c43;
}

html .nav-pills-secondary .nav-link.active,
html .nav-pills-secondary > li.active > a {
  background-color: #e4002c;
}

html .nav-pills-secondary .nav-link.active:hover,
html .nav-pills-secondary > li.active > a:hover,
html .nav-pills-secondary .nav-link.active:focus,
html .nav-pills-secondary > li.active > a:focus {
  background-color: #e4002c;
}

html .nav-pills-tertiary a {
  color: #8a3636;
}

html .nav-pills-tertiary a:hover {
  color: #30bec6;
}

html .nav-pills-tertiary a:focus {
  color: #30bec6;
}

html .nav-pills-tertiary a:active {
  color: #26969c;
}

html .nav-pills-tertiary .nav-link.active,
html .nav-pills-tertiary > li.active > a {
  background-color: #8a3636;
}

html .nav-pills-tertiary .nav-link.active:hover,
html .nav-pills-tertiary > li.active > a:hover,
html .nav-pills-tertiary .nav-link.active:focus,
html .nav-pills-tertiary > li.active > a:focus {
  background-color: #8a3636;
}

html .nav-pills-dark a {
  color: #212529;
}

html .nav-pills-dark a:hover {
  color: #2c3237;
}

html .nav-pills-dark a:focus {
  color: #2c3237;
}

html .nav-pills-dark a:active {
  color: #16181b;
}

html .nav-pills-dark .nav-link.active,
html .nav-pills-dark > li.active > a {
  background-color: #212529;
}

html .nav-pills-dark .nav-link.active:hover,
html .nav-pills-dark > li.active > a:hover,
html .nav-pills-dark .nav-link.active:focus,
html .nav-pills-dark > li.active > a:focus {
  background-color: #212529;
}

html .nav-pills-light a {
  color: #FFF;
}

html .nav-pills-light a:hover {
  color: #ffffff;
}

html .nav-pills-light a:focus {
  color: #ffffff;
}

html .nav-pills-light a:active {
  color: #f2f2f2;
}

html .nav-pills-light .nav-link.active,
html .nav-pills-light > li.active > a {
  background-color: #FFF;
}

html .nav-pills-light .nav-link.active:hover,
html .nav-pills-light > li.active > a:hover,
html .nav-pills-light .nav-link.active:focus,
html .nav-pills-light > li.active > a:focus {
  background-color: #FFF;
}

.nav-link {
  color: #151515;
}

.nav-link:hover {
  color: #373737;
}

.nav-link:focus {
  color: #373737;
}

.nav-link:active {
  color: #1d1d1d;
}

.section-scroll-dots-navigation-colored > ul > li.active > a:before {
  background: #151515;
}

.sort-source-wrapper .nav > li.active > a {
  color: #151515;
}

.sort-source-wrapper .nav > li.active > a:hover,
.sort-source-wrapper .nav > li.active > a:focus {
  color: #151515;
}

.sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #151515;
}

.sort-source.sort-source-style-3 > li.active > a {
  border-bottom-color: #151515 !important;
  color: #151515 !important;
}

html .badge-primary {
  background-color: #151515;
}

html .badge-secondary {
  background-color: #e4002c;
}

html .badge-tertiary {
  background-color: #8a3636;
}

html .badge-dark {
  background-color: #212529;
}

html .badge-light {
  background-color: #FFF;
}

html .overlay-color-primary:not(.no-skin):before {
  background-color: #151515 !important;
}

html .overlay-color-secondary:not(.no-skin):before {
  background-color: #e4002c !important;
}

html .overlay-color-tertiary:not(.no-skin):before {
  background-color: #8a3636 !important;
}

html .overlay-color-dark:not(.no-skin):before {
  background-color: #212529 !important;
}

html .overlay-color-light:not(.no-skin):before {
  background-color: #FFF !important;
}

.overlay-gradient:before {
  background-color: #8a3636 !important;
  background-image: linear-gradient(to right, #8a3636 0%, #383f48 100%) !important;
}

.btn-link {
  color: #151515;
}

.btn-link:hover {
  color: #373737;
}

.btn-link:active {
  color: #1d1d1d;
}

html .btn-primary {
  background-color: #151515;
  border-color: #151515 #151515 #101010;
  color: #FFF;
  --color: #151515;
  --hover: #3d3d3d;
  --disabled: #151515;
  --active: #171717;
}

html .btn-primary:hover,
html .btn-primary.hover {
  border-color: #444444 #444444 #151515;
  color: #FFF;
}

html .btn-primary:hover:not(.bg-transparent),
html .btn-primary.hover:not(.bg-transparent) {
  background-color: #3d3d3d;
}

html .btn-primary:focus,
html .btn-primary.focus {
  border-color: #101010 #101010 #000000;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

html .btn-primary:focus:not(.bg-transparent),
html .btn-primary.focus:not(.bg-transparent) {
  background-color: #171717;
}

html .btn-primary.disabled,
html .btn-primary:disabled {
  border-color: #151515 #151515 #101010;
}

html .btn-primary.disabled:not(.bg-transparent),
html .btn-primary:disabled:not(.bg-transparent) {
  background-color: #151515;
}

html .btn-primary:active,
html .btn-primary.active {
  border-color: #101010 #101010 #000000 !important;
}

html .btn-primary:active:not(.bg-transparent),
html .btn-primary.active:not(.bg-transparent) {
  background-color: #171717 !important;
  background-image: none !important;
}

html .btn-primary-scale-2 {
  background-color: #101010;
  border-color: #101010 #101010 #000000;
  color: #FFF;
}

html .btn-primary-scale-2:hover,
html .btn-primary-scale-2.hover {
  border-color: #151515 #151515 #101010;
  color: #FFF;
}

html .btn-primary-scale-2:hover:not(.bg-transparent),
html .btn-primary-scale-2.hover:not(.bg-transparent) {
  background-color: #242424;
}

html .btn-primary-scale-2:focus,
html .btn-primary-scale-2.focus {
  border-color: #000000 #000000 #000000;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(16, 16, 16, 0.5);
}

html .btn-primary-scale-2:focus:not(.bg-transparent),
html .btn-primary-scale-2.focus:not(.bg-transparent) {
  background-color: #000000;
}

html .btn-primary-scale-2.disabled,
html .btn-primary-scale-2:disabled {
  border-color: #101010 #101010 #000000;
}

html .btn-primary-scale-2.disabled:not(.bg-transparent),
html .btn-primary-scale-2:disabled:not(.bg-transparent) {
  background-color: #101010;
}

html .btn-primary-scale-2:active,
html .btn-primary-scale-2.active {
  border-color: #000000 #000000 #000000 !important;
}

html .btn-primary-scale-2:active:not(.bg-transparent),
html .btn-primary-scale-2.active:not(.bg-transparent) {
  background-color: #000000 !important;
  background-image: none !important;
}

html .show > .btn-primary.dropdown-toggle,
html .show > .btn-primary-scale-2.dropdown-toggle {
  background-color: #171717 !important;
  background-image: none !important;
  border-color: #101010 #101010 #000000 !important;
}

html .btn-check:active + .btn-primary,
html .btn-check:checked + .btn-primary,
html .btn-check:focus + .btn-primary {
  background-color: #1d1d1d;
  border-color: #151515 #151515 #1d1d1d;
  color: #FFF;
}

html .btn-secondary {
  background-color: #e4002c;
  border-color: #e4002c #e4002c #dc372d;
  color: #FFF;
  --color: #e4002c;
  --hover: #e9807a;
  --disabled: #e4002c;
  --active: #dd4238;
}

html .btn-secondary:hover:not(.bg-transparent),
html .btn-secondary.hover:not(.bg-transparent) {
  background-color: #bb0427;
}

html .btn-secondary:focus,
html .btn-secondary.focus {
  border-color: #dc372d #dc372d #b7281f;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(227, 97, 89, 0.5);
}

html .btn-secondary:focus:not(.bg-transparent),
html .btn-secondary.focus:not(.bg-transparent) {
  background-color: #dd4238;
}

html .btn-secondary.disabled,
html .btn-secondary:disabled {
  border-color: #e4002c #e4002c #dc372d;
}

html .btn-secondary.disabled:not(.bg-transparent),
html .btn-secondary:disabled:not(.bg-transparent) {
  background-color: #e4002c;
}

html .btn-secondary:active,
html .btn-secondary.active {
  border-color: #dc372d #dc372d #b7281f !important;
}

html .btn-secondary:active:not(.bg-transparent),
html .btn-secondary.active:not(.bg-transparent) {
  background-color: #dd4238 !important;
  background-image: none !important;
}

html .btn-secondary-scale-2 {
  background-color: #dc372d;
  border-color: #dc372d #dc372d #b7281f;
  color: #FFF;
}

html .btn-secondary-scale-2:hover,
html .btn-secondary-scale-2.hover {
  border-color: #e4002c #e4002c #dc372d;
  color: #FFF;
}

html .btn-secondary-scale-2:hover:not(.bg-transparent),
html .btn-secondary-scale-2.hover:not(.bg-transparent) {
  background-color: #e1574e;
}

html .btn-secondary-scale-2:focus,
html .btn-secondary-scale-2.focus {
  border-color: #b7281f #b7281f #8b1e18;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(220, 55, 45, 0.5);
}

html .btn-secondary-scale-2:focus:not(.bg-transparent),
html .btn-secondary-scale-2.focus:not(.bg-transparent) {
  background-color: #c22a21;
}

html .btn-secondary-scale-2.disabled,
html .btn-secondary-scale-2:disabled {
  border-color: #dc372d #dc372d #b7281f;
}

html .btn-secondary-scale-2.disabled:not(.bg-transparent),
html .btn-secondary-scale-2:disabled:not(.bg-transparent) {
  background-color: #dc372d;
}

html .btn-secondary-scale-2:active,
html .btn-secondary-scale-2.active {
  border-color: #b7281f #b7281f #8b1e18 !important;
}

html .btn-secondary-scale-2:active:not(.bg-transparent),
html .btn-secondary-scale-2.active:not(.bg-transparent) {
  background-color: #c22a21 !important;
  background-image: none !important;
}

html .show > .btn-secondary.dropdown-toggle,
html .show > .btn-secondary-scale-2.dropdown-toggle {
  background-color: #dd4238 !important;
  background-image: none !important;
  border-color: #dc372d #dc372d #b7281f !important;
}

html .btn-check:active + .btn-secondary,
html .btn-check:checked + .btn-secondary,
html .btn-check:focus + .btn-secondary {
  background-color: #df4c43;
  border-color: #e4002c #e4002c #df4c43;
  color: #FFF;
}

html .btn-tertiary {
  background-color: #8a3636;
  border-color: #8a3636 #8a3636 #218388;
  color: #FFF;
  --color: #8a3636;
  --hover: #34c6ce;
  --disabled: #8a3636;
  --active: #248c92;
}

html .btn-tertiary:hover,
html .btn-tertiary.hover {
  border-color: #3fc9d0 #3fc9d0 #8a3636;
  color: #FFF;
}

html .btn-tertiary:hover:not(.bg-transparent),
html .btn-tertiary.hover:not(.bg-transparent) {
  background-color: #34c6ce;
}

html .btn-tertiary:focus,
html .btn-tertiary.focus {
  border-color: #218388 #218388 #175b5f;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
}

html .btn-tertiary:focus:not(.bg-transparent),
html .btn-tertiary.focus:not(.bg-transparent) {
  background-color: #248c92;
}

html .btn-tertiary.disabled,
html .btn-tertiary:disabled {
  border-color: #8a3636 #8a3636 #218388;
}

html .btn-tertiary.disabled:not(.bg-transparent),
html .btn-tertiary:disabled:not(.bg-transparent) {
  background-color: #8a3636;
}

html .btn-tertiary:active,
html .btn-tertiary.active {
  border-color: #218388 #218388 #175b5f !important;
}

html .btn-tertiary:active:not(.bg-transparent),
html .btn-tertiary.active:not(.bg-transparent) {
  background-color: #248c92 !important;
  background-image: none !important;
}

html .btn-tertiary-scale-2 {
  background-color: #218388;
  border-color: #218388 #218388 #175b5f;
  color: #FFF;
}

html .btn-tertiary-scale-2:hover,
html .btn-tertiary-scale-2.hover {
  border-color: #8a3636 #8a3636 #218388;
  color: #FFF;
}

html .btn-tertiary-scale-2:hover:not(.bg-transparent),
html .btn-tertiary-scale-2.hover:not(.bg-transparent) {
  background-color: #29a0a7;
}

html .btn-tertiary-scale-2:focus,
html .btn-tertiary-scale-2.focus {
  border-color: #175b5f #175b5f #0d3436;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(33, 131, 136, 0.5);
}

html .btn-tertiary-scale-2:focus:not(.bg-transparent),
html .btn-tertiary-scale-2.focus:not(.bg-transparent) {
  background-color: #1a6569;
}

html .btn-tertiary-scale-2.disabled,
html .btn-tertiary-scale-2:disabled {
  border-color: #218388 #218388 #175b5f;
}

html .btn-tertiary-scale-2.disabled:not(.bg-transparent),
html .btn-tertiary-scale-2:disabled:not(.bg-transparent) {
  background-color: #218388;
}

html .btn-tertiary-scale-2:active,
html .btn-tertiary-scale-2.active {
  border-color: #175b5f #175b5f #0d3436 !important;
}

html .btn-tertiary-scale-2:active:not(.bg-transparent),
html .btn-tertiary-scale-2.active:not(.bg-transparent) {
  background-color: #1a6569 !important;
  background-image: none !important;
}

html .show > .btn-tertiary.dropdown-toggle,
html .show > .btn-tertiary-scale-2.dropdown-toggle {
  background-color: #248c92 !important;
  background-image: none !important;
  border-color: #218388 #218388 #175b5f !important;
}

html .btn-check:active + .btn-tertiary,
html .btn-check:checked + .btn-tertiary,
html .btn-check:focus + .btn-tertiary {
  background-color: #26969c;
  border-color: #8a3636 #8a3636 #26969c;
  color: #FFF;
}

html .btn-dark {
  background-color: #212529;
  border-color: #212529 #212529 #0a0c0d;
  color: #FFF;
  --color: #212529;
  --hover: #32383e;
  --disabled: #212529;
  --active: #101214;
}

html .btn-dark:hover,
html .btn-dark.hover {
  border-color: #383f45 #383f45 #212529;
  color: #FFF;
}

html .btn-dark:hover:not(.bg-transparent),
html .btn-dark.hover:not(.bg-transparent) {
  background-color: #32383e;
}

html .btn-dark:focus,
html .btn-dark.focus {
  border-color: #0a0c0d #0a0c0d #000000;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

html .btn-dark:focus:not(.bg-transparent),
html .btn-dark.focus:not(.bg-transparent) {
  background-color: #101214;
}

html .btn-dark.disabled,
html .btn-dark:disabled {
  border-color: #212529 #212529 #0a0c0d;
}

html .btn-dark.disabled:not(.bg-transparent),
html .btn-dark:disabled:not(.bg-transparent) {
  background-color: #212529;
}

html .btn-dark:active,
html .btn-dark.active {
  border-color: #0a0c0d #0a0c0d #000000 !important;
}

html .btn-dark:active:not(.bg-transparent),
html .btn-dark.active:not(.bg-transparent) {
  background-color: #101214 !important;
  background-image: none !important;
}

html .btn-dark-scale-2 {
  background-color: #0a0c0d;
  border-color: #0a0c0d #0a0c0d #000000;
  color: #FFF;
}

html .btn-dark-scale-2:hover,
html .btn-dark-scale-2.hover {
  border-color: #212529 #212529 #0a0c0d;
  color: #FFF;
}

html .btn-dark-scale-2:hover:not(.bg-transparent),
html .btn-dark-scale-2.hover:not(.bg-transparent) {
  background-color: #1b1f22;
}

html .btn-dark-scale-2:focus,
html .btn-dark-scale-2.focus {
  border-color: #000000 #000000 #000000;
  color: #FFF;
  box-shadow: 0 0 0 3px rgba(10, 11, 13, 0.5);
}

html .btn-dark-scale-2:focus:not(.bg-transparent),
html .btn-dark-scale-2.focus:not(.bg-transparent) {
  background-color: #000000;
}

html .btn-dark-scale-2.disabled,
html .btn-dark-scale-2:disabled {
  border-color: #0a0c0d #0a0c0d #000000;
}

html .btn-dark-scale-2.disabled:not(.bg-transparent),
html .btn-dark-scale-2:disabled:not(.bg-transparent) {
  background-color: #0a0c0d;
}

html .btn-dark-scale-2:active,
html .btn-dark-scale-2.active {
  border-color: #000000 #000000 #000000 !important;
}

html .btn-dark-scale-2:active:not(.bg-transparent),
html .btn-dark-scale-2.active:not(.bg-transparent) {
  background-color: #000000 !important;
  background-image: none !important;
}

html .show > .btn-dark.dropdown-toggle,
html .show > .btn-dark-scale-2.dropdown-toggle {
  background-color: #101214 !important;
  background-image: none !important;
  border-color: #0a0c0d #0a0c0d #000000 !important;
}

html .btn-check:active + .btn-dark,
html .btn-check:checked + .btn-dark,
html .btn-check:focus + .btn-dark {
  background-color: #16181b;
  border-color: #212529 #212529 #16181b;
  color: #FFF;
}

html .btn-light {
  background-color: #FFF;
  border-color: #FFF #FFF #e6e6e6;
  color: #777;
  --color: #FFF;
  --hover: #ffffff;
  --disabled: #FFF;
  --active: #ececec;
}

html .btn-light:hover,
html .btn-light.hover {
  border-color: #ffffff #ffffff #FFF;
  color: #777;
}

html .btn-light:hover:not(.bg-transparent),
html .btn-light.hover:not(.bg-transparent) {
  background-color: #ffffff;
}

html .btn-light:focus,
html .btn-light.focus {
  border-color: #e6e6e6 #e6e6e6 #cccccc;
  color: #777;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-light:focus:not(.bg-transparent),
html .btn-light.focus:not(.bg-transparent) {
  background-color: #ececec;
}

html .btn-light.disabled,
html .btn-light:disabled {
  border-color: #FFF #FFF #e6e6e6;
}

html .btn-light.disabled:not(.bg-transparent),
html .btn-light:disabled:not(.bg-transparent) {
  background-color: #FFF;
}

html .btn-light:active,
html .btn-light.active {
  border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

html .btn-light:active:not(.bg-transparent),
html .btn-light.active:not(.bg-transparent) {
  background-color: #ececec !important;
  background-image: none !important;
}

html .btn-light-scale-2 {
  background-color: #e6e6e6;
  border-color: #e6e6e6 #e6e6e6 #cccccc;
  color: #777;
}

html .btn-light-scale-2:hover,
html .btn-light-scale-2.hover {
  border-color: #ffffff #ffffff #e6e6e6;
  color: #777;
}

html .btn-light-scale-2:hover:not(.bg-transparent),
html .btn-light-scale-2.hover:not(.bg-transparent) {
  background-color: #f9f9f9;
}

html .btn-light-scale-2:focus,
html .btn-light-scale-2.focus {
  border-color: #cccccc #cccccc #b3b3b3;
  color: #777;
  box-shadow: 0 0 0 3px rgba(230, 230, 230, 0.5);
}

html .btn-light-scale-2:focus:not(.bg-transparent),
html .btn-light-scale-2.focus:not(.bg-transparent) {
  background-color: #d2d2d2;
}

html .btn-light-scale-2.disabled,
html .btn-light-scale-2:disabled {
  border-color: #e6e6e6 #e6e6e6 #cccccc;
}

html .btn-light-scale-2.disabled:not(.bg-transparent),
html .btn-light-scale-2:disabled:not(.bg-transparent) {
  background-color: #e6e6e6;
}

html .btn-light-scale-2:active,
html .btn-light-scale-2.active {
  border-color: #cccccc #cccccc #b3b3b3 !important;
}

html .btn-light-scale-2:active:not(.bg-transparent),
html .btn-light-scale-2.active:not(.bg-transparent) {
  background-color: #d2d2d2 !important;
  background-image: none !important;
}

html .show > .btn-light.dropdown-toggle,
html .show > .btn-light-scale-2.dropdown-toggle {
  background-color: #ececec !important;
  background-image: none !important;
  border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

html .btn-check:active + .btn-light,
html .btn-check:checked + .btn-light,
html .btn-check:focus + .btn-light {
  background-color: #f2f2f2;
  border-color: #FFF #FFF #f2f2f2;
  color: #777;
}

html .btn-outline.btn-primary {
  color: #151515;
  background-color: transparent;
  background-image: none;
  border-color: #151515;
}

html .btn-outline.btn-primary:hover,
html .btn-outline.btn-primary.hover {
  color: #FFF;
  background-color: #151515;
  border-color: #151515;
}

html .btn-outline.btn-primary:focus,
html .btn-outline.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

html .btn-outline.btn-primary.disabled,
html .btn-outline.btn-primary:disabled {
  color: #151515;
  background-color: transparent;
}

html .btn-outline.btn-primary:active,
html .btn-outline.btn-primary.active {
  color: #FFF !important;
  background-color: #151515 !important;
  border-color: #151515 !important;
}

html .show > .btn-outline.btn-primary.dropdown-toggle {
  color: #FFF !important;
  background-color: #151515 !important;
  border-color: #151515 !important;
}

html .btn-outline.btn-secondary {
  color: #e4002c;
  background-color: transparent;
  background-image: none;
  border-color: #e4002c;
}

html .btn-outline.btn-secondary:hover,
html .btn-outline.btn-secondary.hover {
  color: #FFF;
  background-color: #e4002c;
  border-color: #e4002c;
}

html .btn-outline.btn-secondary:focus,
html .btn-outline.btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(227, 97, 89, 0.5);
}

html .btn-outline.btn-secondary.disabled,
html .btn-outline.btn-secondary:disabled {
  color: #e4002c;
  background-color: transparent;
}

html .btn-outline.btn-secondary:active,
html .btn-outline.btn-secondary.active {
  color: #FFF !important;
  background-color: #e4002c !important;
  border-color: #e4002c !important;
}

html .show > .btn-outline.btn-secondary.dropdown-toggle {
  color: #FFF !important;
  background-color: #e4002c !important;
  border-color: #e4002c !important;
}

html .btn-outline.btn-tertiary {
  color: #8a3636;
  background-color: transparent;
  background-image: none;
  border-color: #8a3636;
}

html .btn-outline.btn-tertiary:hover,
html .btn-outline.btn-tertiary.hover {
  color: #FFF;
  background-color: #8a3636;
  border-color: #8a3636;
}

html .btn-outline.btn-tertiary:focus,
html .btn-outline.btn-tertiary.focus {
  box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
}

html .btn-outline.btn-tertiary.disabled,
html .btn-outline.btn-tertiary:disabled {
  color: #8a3636;
  background-color: transparent;
}

html .btn-outline.btn-tertiary:active,
html .btn-outline.btn-tertiary.active {
  color: #FFF !important;
  background-color: #8a3636 !important;
  border-color: #8a3636 !important;
}

html .show > .btn-outline.btn-tertiary.dropdown-toggle {
  color: #FFF !important;
  background-color: #8a3636 !important;
  border-color: #8a3636 !important;
}

html .btn-outline.btn-dark {
  color: #212529;
  background-color: transparent;
  background-image: none;
  border-color: #212529;
}

html .btn-outline.btn-dark:hover,
html .btn-outline.btn-dark.hover {
  color: #FFF;
  background-color: #212529;
  border-color: #212529;
}

html .btn-outline.btn-dark:focus,
html .btn-outline.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

html .btn-outline.btn-dark.disabled,
html .btn-outline.btn-dark:disabled {
  color: #212529;
  background-color: transparent;
}

html .btn-outline.btn-dark:active,
html .btn-outline.btn-dark.active {
  color: #FFF !important;
  background-color: #212529 !important;
  border-color: #212529 !important;
}

html .show > .btn-outline.btn-dark.dropdown-toggle {
  color: #FFF !important;
  background-color: #212529 !important;
  border-color: #212529 !important;
}

html .btn-outline.btn-light {
  color: #FFF;
  background-color: transparent;
  background-image: none;
  border-color: #FFF;
}

html .btn-outline.btn-light:hover,
html .btn-outline.btn-light.hover {
  color: #777;
  background-color: #FFF;
  border-color: #FFF;
}

html .btn-outline.btn-light:focus,
html .btn-outline.btn-light.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-outline.btn-light.disabled,
html .btn-outline.btn-light:disabled {
  color: #FFF;
  background-color: transparent;
}

html .btn-outline.btn-light:active,
html .btn-outline.btn-light.active {
  color: #777 !important;
  background-color: #FFF !important;
  border-color: #FFF !important;
}

html .show > .btn-outline.btn-light.dropdown-toggle {
  color: #777 !important;
  background-color: #FFF !important;
  border-color: #FFF !important;
}

html .btn-with-arrow.btn-primary {
  background-color: transparent;
  border-color: transparent;
  color: #151515;
}

html .btn-with-arrow.btn-primary:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #151515 !important;
}

html .btn-with-arrow.btn-primary span {
  background-color: #151515;
  box-shadow: 2px 3px 18px -3px #151515;
}

html .btn-with-arrow.btn-primary.btn-outline {
  border-color: #151515 !important;
}

html .btn-with-arrow.btn-primary.btn-outline:hover span {
  background-color: #373737 !important;
  box-shadow: none;
}

html .btn-with-arrow-solid.btn-primary span {
  background-color: #1d1d1d;
}

html .btn-with-arrow.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  color: #e4002c;
}

html .btn-with-arrow.btn-secondary:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #e4002c !important;
}

html .btn-with-arrow.btn-secondary span {
  background-color: #e4002c;
  box-shadow: 2px 3px 18px -3px #e4002c;
}

html .btn-with-arrow.btn-secondary.btn-outline {
  border-color: #e4002c !important;
}

html .btn-with-arrow.btn-secondary.btn-outline:hover span {
  background-color: #e7766f !important;
  box-shadow: none;
}

html .btn-with-arrow-solid.btn-secondary span {
  background-color: #df4c43;
}

html .btn-with-arrow.btn-tertiary {
  background-color: transparent;
  border-color: transparent;
  color: #8a3636;
}

html .btn-with-arrow.btn-tertiary:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #8a3636 !important;
}

html .btn-with-arrow.btn-tertiary span {
  background-color: #8a3636;
  box-shadow: 2px 3px 18px -3px #8a3636;
}

html .btn-with-arrow.btn-tertiary.btn-outline {
  border-color: #8a3636 !important;
}

html .btn-with-arrow.btn-tertiary.btn-outline:hover span {
  background-color: #30bec6 !important;
  box-shadow: none;
}

html .btn-with-arrow-solid.btn-tertiary span {
  background-color: #26969c;
}

html .btn-with-arrow.btn-dark {
  background-color: transparent;
  border-color: transparent;
  color: #212529;
}

html .btn-with-arrow.btn-dark:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #212529 !important;
}

html .btn-with-arrow.btn-dark span {
  background-color: #212529;
  box-shadow: 2px 3px 18px -3px #212529;
}

html .btn-with-arrow.btn-dark.btn-outline {
  border-color: #212529 !important;
}

html .btn-with-arrow.btn-dark.btn-outline:hover span {
  background-color: #2c3237 !important;
  box-shadow: none;
}

html .btn-with-arrow-solid.btn-dark span {
  background-color: #16181b;
}

html .btn-with-arrow.btn-light {
  background-color: transparent;
  border-color: transparent;
  color: #FFF;
}

html .btn-with-arrow.btn-light:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #FFF !important;
}

html .btn-with-arrow.btn-light span {
  background-color: #FFF;
  box-shadow: 2px 3px 18px -3px #FFF;
}

html .btn-with-arrow.btn-light.btn-outline {
  border-color: #FFF !important;
}

html .btn-with-arrow.btn-light.btn-outline:hover span {
  background-color: #ffffff !important;
  box-shadow: none;
}

html .btn-with-arrow-solid.btn-light span {
  background-color: #f2f2f2;
}

.btn-gradient:not(.btn-outline) {
  background: linear-gradient(135deg, #151515 0%, #e4002c 80%) !important;
  color: #FFF;
}

.btn-gradient:not(.btn-outline):hover,
.btn-gradient:not(.btn-outline).hover {
  background: linear-gradient(135deg, #3d3d3d 0%, #e9807a 80%) !important;
  color: #FFF;
}

.btn-gradient:not(.btn-outline):focus,
.btn-gradient:not(.btn-outline).focus {
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.btn-gradient:not(.btn-outline).disabled,
.btn-gradient:not(.btn-outline):disabled {
  background: linear-gradient(135deg, #151515 0%, #e4002c 80%) !important;
}

.btn-gradient:not(.btn-outline):active,
.btn-gradient:not(.btn-outline).active {
  background: linear-gradient(135deg, #171717 0%, #dd4238 80%) !important;
  color: #FFF !important;
}

.btn-gradient.btn-outline {
  -o-border-image: linear-gradient(135deg, #151515 0%, #e4002c 80%);
  border-image: linear-gradient(135deg, #151515 0%, #e4002c 80%);
  color: #151515;
}

.btn-gradient.btn-outline:hover,
.btn-gradient.btn-outline.hover {
  -o-border-image: linear-gradient(135deg, #3d3d3d 0%, #e9807a 80%);
  border-image: linear-gradient(135deg, #3d3d3d 0%, #e9807a 80%);
  color: #3d3d3d;
}

.btn-gradient.btn-outline:focus,
.btn-gradient.btn-outline.focus {
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.btn-gradient.btn-outline.disabled,
.btn-gradient.btn-outline:disabled {
  -o-border-image: linear-gradient(135deg, #151515 0%, #e4002c 80%);
  border-image: linear-gradient(135deg, #151515 0%, #e4002c 80%);
}

.btn-gradient.btn-outline:active,
.btn-gradient.btn-outline.active {
  -o-border-image: linear-gradient(135deg, #171717 0%, #dd4238 80%);
  border-image: linear-gradient(135deg, #171717 0%, #dd4238 80%);
  color: #171717;
}

.btn-gradient-primary:not(.btn-outline) {
  background: linear-gradient(135deg, #343434 0%, #252525 80%) !important;
  color: #FFF;
}

.btn-gradient-primary:not(.btn-outline):hover,
.btn-gradient-primary:not(.btn-outline).hover {
  background: linear-gradient(135deg, #323232 0%, #3d3d3d 80%) !important;
  color: #FFF;
}

.btn-gradient-primary:not(.btn-outline):focus,
.btn-gradient-primary:not(.btn-outline).focus {
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.btn-gradient-primary:not(.btn-outline).disabled,
.btn-gradient-primary:not(.btn-outline):disabled {
  background: linear-gradient(135deg, #343434 80%, #252525 0%) !important;
}

.btn-gradient-primary:not(.btn-outline):active,
.btn-gradient-primary:not(.btn-outline).active {
  background: linear-gradient(135deg, #323232 0%, #3d3d3d 80%) !important;
  color: #FFF !important;
}

.btn-gradient-primary.btn-outline {
  -o-border-image: linear-gradient(135deg, #151515 0%, #3e3e3e 80%);
  border-image: linear-gradient(135deg, #151515 0%, #3e3e3e 80%);
  color: #151515;
}

.btn-gradient-primary.btn-outline:hover,
.btn-gradient-primary.btn-outline.hover {
  -o-border-image: linear-gradient(135deg, #3d3d3d 0%, #3d3d3d 80%);
  border-image: linear-gradient(135deg, #3d3d3d 0%, #3d3d3d 80%);
  color: #3d3d3d;
}

.btn-gradient-primary.btn-outline:focus,
.btn-gradient-primary.btn-outline.focus {
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.btn-gradient-primary.btn-outline.disabled,
.btn-gradient-primary.btn-outline:disabled {
  -o-border-image: linear-gradient(135deg, #151515 0%, #3e3e3e 80%);
  border-image: linear-gradient(135deg, #151515 0%, #3e3e3e 80%);
}

.btn-gradient-primary.btn-outline:active,
.btn-gradient-primary.btn-outline.active {
  -o-border-image: linear-gradient(135deg, #3d3d3d 0%, #3d3d3d 80%);
  border-image: linear-gradient(135deg, #3d3d3d 0%, #3d3d3d 80%);
  color: #3d3d3d;
}

.pagination > a,
.pagination > a:hover,
.pagination > a:focus,
.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #151515;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination .page-item.active .page-link {
  background-color: #151515 !important;
  border-color: #151515;
}

body.dark .pagination > li > a,
body.dark .pagination > li > span,
body.dark .pagination > li > a:hover,
body.dark .pagination > li > span:hover,
body.dark .pagination > li > a:focus,
body.dark .pagination > li > span:focus {
  color: #151515;
}

body.dark .pagination > .active > a,
body.dark .pagination > .active > span,
body.dark .pagination > .active > a:hover,
body.dark .pagination > .active > span:hover,
body.dark .pagination > .active > a:focus,
body.dark .pagination > .active > span:focus {
  background-color: #151515;
  border-color: #151515;
}

.pagination > .active > a,
body.dark .pagination > .active > a {
  background-color: #151515;
  border-color: #151515 #151515 #101010;
  color: #fff;
}

.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover,
.pagination > .active > a.hover,
body.dark .pagination > .active > a.hover {
  border-color: #444444 #444444 #151515;
  color: #fff;
}

.pagination > .active > a:hover:not(.bg-transparent),
body.dark .pagination > .active > a:hover:not(.bg-transparent),
.pagination > .active > a.hover:not(.bg-transparent),
body.dark .pagination > .active > a.hover:not(.bg-transparent) {
  background-color: #3d3d3d;
}

.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.pagination > .active > a.focus,
body.dark .pagination > .active > a.focus {
  border-color: #101010 #101010 #000000;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.pagination > .active > a:focus:not(.bg-transparent),
body.dark .pagination > .active > a:focus:not(.bg-transparent),
.pagination > .active > a.focus:not(.bg-transparent),
body.dark .pagination > .active > a.focus:not(.bg-transparent) {
  background-color: #171717;
}

.pagination > .active > a.disabled,
body.dark .pagination > .active > a.disabled,
.pagination > .active > a:disabled,
body.dark .pagination > .active > a:disabled {
  border-color: #151515 #151515 #101010;
}

.pagination > .active > a.disabled:not(.bg-transparent),
body.dark .pagination > .active > a.disabled:not(.bg-transparent),
.pagination > .active > a:disabled:not(.bg-transparent),
body.dark .pagination > .active > a:disabled:not(.bg-transparent) {
  background-color: #151515;
}

.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.pagination > .active > a.active,
body.dark .pagination > .active > a.active {
  border-color: #101010 #101010 #000000 !important;
}

.pagination > .active > a:active:not(.bg-transparent),
body.dark .pagination > .active > a:active:not(.bg-transparent),
.pagination > .active > a.active:not(.bg-transparent),
body.dark .pagination > .active > a.active:not(.bg-transparent) {
  background-color: #171717 !important;
  background-image: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #151515;
}

.custom-checkbox-switch:checked {
  background-color: #151515;
  border-color: #151515;
}

html .alert-primary {
  background-color: #151515;
  border-color: #222222;
  color: #FFF;
}

html .alert-primary .alert-link {
  color: #FFF;
}

html .alert-secondary {
  background-color: #e4002c;
  border-color: #e1554c;
  color: #FFF;
}

html .alert-secondary .alert-link {
  color: #FFF;
}

html .alert-tertiary {
  background-color: #8a3636;
  border-color: #289ea5;
  color: #FFF;
}

html .alert-tertiary .alert-link {
  color: #FFF;
}

html .alert-dark {
  background-color: #212529;
  border-color: #1a1d21;
  color: #FFF;
}

html .alert-dark .alert-link {
  color: #FFF;
}

html .alert-light {
  background-color: #FFF;
  border-color: #f7f7f7;
  color: #777;
}

html .alert-light .alert-link {
  color: #777;
}

html .progress-bar-primary {
  background-color: #151515;
}

html .progress-bar-secondary {
  background-color: #e4002c;
}

html .progress-bar-tertiary {
  background-color: #8a3636;
}

html .progress-bar-dark {
  background-color: #212529;
}

html .progress-bar-light {
  background-color: #FFF;
}

html .word-rotator.loading-bar-primary .word-rotator-words::after {
  background-color: #151515;
}

html .word-rotator.loading-bar-secondary .word-rotator-words::after {
  background-color: #e4002c;
}

html .word-rotator.loading-bar-tertiary .word-rotator-words::after {
  background-color: #8a3636;
}

html .word-rotator.loading-bar-dark .word-rotator-words::after {
  background-color: #212529;
}

html .word-rotator.loading-bar-light .word-rotator-words::after {
  background-color: #FFF;
}

/* Mark */
html .mark.mark-gradient.mark-gradient-primary::before {
  background: #151515;
  background: linear-gradient(90deg, #151515 0%, rgba(42, 42, 42, 0) 100%);
}

html .mark.mark-gradient.mark-gradient-secondary::before {
  background: #e4002c;
  background: linear-gradient(90deg, #e4002c 0%, rgba(227, 97, 89, 0) 100%);
}

html .mark.mark-gradient.mark-gradient-tertiary::before {
  background: #8a3636;
  background: linear-gradient(90deg, #8a3636 0%, rgba(43, 170, 177, 0) 100%);
}

html .mark.mark-gradient.mark-gradient-dark::before {
  background: #212529;
  background: linear-gradient(90deg, #212529 0%, rgba(33, 37, 41, 0) 100%);
}

html .mark.mark-gradient.mark-gradient-light::before {
  background: #ffffff;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.process .process-step-circle {
  border-color: #151515;
}

.process .process-step:hover .process-step-circle {
  background-color: #151515;
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
  color: #FFF !important;
}

.process.process-shapes .process-step-circle .process-step-circle-content,
.process.process-shapes .process-step-circle:before,
.process.process-shapes .process-step-circle:after {
  background-color: #151515;
}

.process-connecting-line .connecting-line {
  background: #151515;
}

html .rating-primary .filled-stars {
  color: #151515;
}

html .rating-secondary .filled-stars {
  color: #e4002c;
}

html .rating-tertiary .filled-stars {
  color: #8a3636;
}

html .rating-dark .filled-stars {
  color: #212529;
}

html .rating-light .filled-stars {
  color: #FFF;
}

html section.section-primary {
  background-color: #151515 !important;
  border-color: #1d1d1d !important;
}

html section.section-primary h1,
html section.section-primary h2,
html section.section-primary h3,
html section.section-primary h4,
html section.section-primary h5,
html section.section-primary h6 {
  color: #FFF;
}

html section.section-primary p {
  color: #e6e6e6;
}

html section.section-primary-scale-2 {
  background-color: #101010 !important;
  border-color: #040404 !important;
}

html section.section-primary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #101010;
}

html section.section-secondary {
  background-color: #e4002c !important;
  border-color: #df4c43 !important;
}

html section.section-secondary h1,
html section.section-secondary h2,
html section.section-secondary h3,
html section.section-secondary h4,
html section.section-secondary h5,
html section.section-secondary h6 {
  color: #FFF;
}

html section.section-secondary p {
  color: #e6e6e6;
}

html section.section-secondary-scale-2 {
  background-color: #dc372d !important;
  border-color: #cd2c23 !important;
}

html section.section-secondary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #dc372d;
}

html section.section-tertiary {
  background-color: #8a3636 !important;
  border-color: #26969c !important;
}

html section.section-tertiary h1,
html section.section-tertiary h2,
html section.section-tertiary h3,
html section.section-tertiary h4,
html section.section-tertiary h5,
html section.section-tertiary h6 {
  color: #FFF;
}

html section.section-tertiary p {
  color: #e6e6e6;
}

html section.section-tertiary-scale-2 {
  background-color: #218388 !important;
  border-color: #1c6f73 !important;
}

html section.section-tertiary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #218388;
}

html section.section-dark {
  background-color: #212529 !important;
  border-color: #16181b !important;
}

html section.section-dark h1,
html section.section-dark h2,
html section.section-dark h3,
html section.section-dark h4,
html section.section-dark h5,
html section.section-dark h6 {
  color: #FFF;
}

html section.section-dark p {
  color: #e6e6e6;
}

html section.section-dark-scale-2 {
  background-color: #0a0c0d !important;
  border-color: #000000 !important;
}

html section.section-dark-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #0a0c0d;
}

html section.section-light {
  background-color: #FFF !important;
  border-color: #f2f2f2 !important;
}

html section.section-light h1,
html section.section-light h2,
html section.section-light h3,
html section.section-light h4,
html section.section-light h5,
html section.section-light h6 {
  color: #777;
}

html section.section-light p {
  color: #5e5e5e;
}

html section.section-light-scale-2 {
  background-color: #e6e6e6 !important;
  border-color: #d9d9d9 !important;
}

html section.section-light-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
  border-top-color: #e6e6e6;
}

section.section.section-background-half-primary-half-secondary {
  background: linear-gradient(to right, #151515 50%, #e4002c 50%);
}

section.section.section-overlay-opacity-gradient:before {
  background: linear-gradient(135deg, #151515 0%, #e4002c 80%);
}

html .bg-color-light-scale-1 {
  background-color: #f7f7f7 !important;
}

html .section.bg-color-light-scale-1,
html .bg-color-light-scale-border-top-1 {
  border-top-color: #efefef !important;
}

html .bg-color-light-scale-2 {
  background-color: #e8e8e8 !important;
}

html .section.bg-color-light-scale-2,
html .bg-color-light-scale-border-top-2 {
  border-top-color: #e0e0e0 !important;
}

html .bg-color-light-scale-3 {
  background-color: #e0e0e0 !important;
}

html .section.bg-color-light-scale-3,
html .bg-color-light-scale-border-top-3 {
  border-top-color: #d8d8d8 !important;
}

html .bg-color-light-scale-4 {
  background-color: #d8d8d8 !important;
}

html .section.bg-color-light-scale-4,
html .bg-color-light-scale-border-top-4 {
  border-top-color: #d1d1d1 !important;
}

html .bg-color-light-scale-5 {
  background-color: #d1d1d1 !important;
}

html .section.bg-color-light-scale-5,
html .bg-color-light-scale-border-top-5 {
  border-top-color: #c9c9c9 !important;
}

html .bg-color-light-scale-6 {
  background-color: #c9c9c9 !important;
}

html .section.bg-color-light-scale-6,
html .bg-color-light-scale-border-top-6 {
  border-top-color: #c1c1c1 !important;
}

html .bg-color-light-scale-7 {
  background-color: #c1c1c1 !important;
}

html .section.bg-color-light-scale-7,
html .bg-color-light-scale-border-top-7 {
  border-top-color: #bababa !important;
}

html .bg-color-light-scale-8 {
  background-color: #bababa !important;
}

html .section.bg-color-light-scale-8,
html .bg-color-light-scale-border-top-8 {
  border-top-color: #b2b2b2 !important;
}

html .bg-color-light-scale-9 {
  background-color: #b2b2b2 !important;
}

html .section.bg-color-light-scale-9,
html .bg-color-light-scale-border-top-9 {
  border-top-color: #ababab !important;
}

html .bg-color-dark-scale-1 {
  background-color: #212529 !important;
}

html .section.bg-color-dark-scale-1,
html .bg-color-dark-scale-border-top-1 {
  border-top-color: #1a1d21 !important;
}

html .bg-color-dark-scale-2 {
  background-color: #1c2023 !important;
}

html .section.bg-color-dark-scale-2,
html .bg-color-dark-scale-border-top-2 {
  border-top-color: #16181b !important;
}

html .bg-color-dark-scale-3 {
  background-color: #181b1e !important;
}

html .section.bg-color-dark-scale-3,
html .bg-color-dark-scale-border-top-3 {
  border-top-color: #111315 !important;
}

html .bg-color-dark-scale-4 {
  background-color: #131618 !important;
}

html .section.bg-color-dark-scale-4,
html .bg-color-dark-scale-border-top-4 {
  border-top-color: #0d0e10 !important;
}

html .bg-color-dark-scale-5 {
  background-color: #0f1112 !important;
}

html .section.bg-color-dark-scale-5,
html .bg-color-dark-scale-border-top-5 {
  border-top-color: #08090a !important;
}

html .bg-color-dark-scale-6 {
  background-color: #0a0c0d !important;
}

html .section.bg-color-dark-scale-6,
html .bg-color-dark-scale-border-top-6 {
  border-top-color: #030404 !important;
}

html .bg-color-dark-scale-7 {
  background-color: #060607 !important;
}

html .section.bg-color-dark-scale-7,
html .bg-color-dark-scale-border-top-7 {
  border-top-color: #000000 !important;
}

html .bg-color-dark-scale-8 {
  background-color: #010101 !important;
}

html .section.bg-color-dark-scale-8,
html .bg-color-dark-scale-border-top-8 {
  border-top-color: #000000 !important;
}

html .bg-color-dark-scale-9 {
  background-color: #000000 !important;
}

html .section.bg-color-dark-scale-9,
html .bg-color-dark-scale-border-top-9 {
  border-top-color: #000000 !important;
}

section.page-header .page-header-title-border {
  background-color: #151515 !important;
}

html .page-header-color.page-header-primary {
  background-color: #151515;
  border-bottom-color: #151515;
  color: #FFF;
}

html .page-header-color.page-header-primary h1 {
  color: #FFF;
}

html .page-header-color.page-header-primary h1 span {
  color: #FFF;
}

html .page-header-color.page-header-primary a {
  color: #FFF;
}

html .page-header-color.page-header-primary .breadcrumb > .active {
  color: #FFF;
}

html .page-header-color.page-header-secondary {
  background-color: #e4002c;
  border-bottom-color: #e4002c;
  color: #FFF;
}

html .page-header-color.page-header-secondary h1 {
  color: #FFF;
}

html .page-header-color.page-header-secondary h1 span {
  color: #FFF;
}

html .page-header-color.page-header-secondary a {
  color: #FFF;
}

html .page-header-color.page-header-secondary .breadcrumb > .active {
  color: #FFF;
}

html .page-header-color.page-header-tertiary {
  background-color: #8a3636;
  border-bottom-color: #8a3636;
  color: #FFF;
}

html .page-header-color.page-header-tertiary h1 {
  color: #FFF;
}

html .page-header-color.page-header-tertiary h1 span {
  color: #FFF;
}

html .page-header-color.page-header-tertiary a {
  color: #FFF;
}

html .page-header-color.page-header-tertiary .breadcrumb > .active {
  color: #FFF;
}

html .page-header-color.page-header-dark {
  background-color: #212529;
  border-bottom-color: #212529;
  color: #FFF;
}

html .page-header-color.page-header-dark h1 {
  color: #FFF;
}

html .page-header-color.page-header-dark h1 span {
  color: #FFF;
}

html .page-header-color.page-header-dark a {
  color: #FFF;
}

html .page-header-color.page-header-dark .breadcrumb > .active {
  color: #FFF;
}

html .page-header-color.page-header-light {
  background-color: #FFF;
  border-bottom-color: #FFF;
  color: #777;
}

html .page-header-color.page-header-light h1 {
  color: #777;
}

html .page-header-color.page-header-light h1 span {
  color: #777;
}

html .page-header-color.page-header-light a {
  color: #777;
}

html .page-header-color.page-header-light .breadcrumb > .active {
  color: #777;
}

html .toggle-primary .toggle label,
html .toggle-primary .toggle .toggle-title {
  color: #151515;
  border-left-color: #151515;
  border-right-color: #151515;
}

html .toggle-primary .toggle.active > label,
html .toggle-primary .toggle.active > .toggle-title {
  background-color: #151515;
  border-color: #151515;
  color: #FFF;
}

html .toggle-primary.toggle-simple .toggle > label:after,
html .toggle-primary.toggle-simple .toggle > .toggle-title:after {
  background-color: #151515;
}

html .toggle-primary.toggle-minimal .toggle.active > label,
html .toggle-primary.toggle-minimal .toggle.active > .toggle-title {
  color: #151515;
}

html .toggle-secondary .toggle label,
html .toggle-secondary .toggle .toggle-title {
  color: #e4002c;
  border-left-color: #e4002c;
  border-right-color: #e4002c;
}

html .toggle-secondary .toggle.active > label,
html .toggle-secondary .toggle.active > .toggle-title {
  background-color: #e4002c;
  border-color: #e4002c;
  color: #FFF;
}

html .toggle-secondary.toggle-simple .toggle > label:after,
html .toggle-secondary.toggle-simple .toggle > .toggle-title:after {
  background-color: #e4002c;
}

html .toggle-secondary.toggle-minimal .toggle.active > label,
html .toggle-secondary.toggle-minimal .toggle.active > .toggle-title {
  color: #e4002c;
}

html .toggle-tertiary .toggle label,
html .toggle-tertiary .toggle .toggle-title {
  color: #8a3636;
  border-left-color: #8a3636;
  border-right-color: #8a3636;
}

html .toggle-tertiary .toggle.active > label,
html .toggle-tertiary .toggle.active > .toggle-title {
  background-color: #8a3636;
  border-color: #8a3636;
  color: #FFF;
}

html .toggle-tertiary.toggle-simple .toggle > label:after,
html .toggle-tertiary.toggle-simple .toggle > .toggle-title:after {
  background-color: #8a3636;
}

html .toggle-tertiary.toggle-minimal .toggle.active > label,
html .toggle-tertiary.toggle-minimal .toggle.active > .toggle-title {
  color: #8a3636;
}

html .toggle-dark .toggle label,
html .toggle-dark .toggle .toggle-title {
  color: #212529;
  border-left-color: #212529;
  border-right-color: #212529;
}

html .toggle-dark .toggle.active > label,
html .toggle-dark .toggle.active > .toggle-title {
  background-color: #212529;
  border-color: #212529;
  color: #FFF;
}

html .toggle-dark.toggle-simple .toggle > label:after,
html .toggle-dark.toggle-simple .toggle > .toggle-title:after {
  background-color: #212529;
}

html .toggle-dark.toggle-minimal .toggle.active > label,
html .toggle-dark.toggle-minimal .toggle.active > .toggle-title {
  color: #212529;
}

html .toggle-light .toggle label,
html .toggle-light .toggle .toggle-title {
  color: #FFF;
  border-left-color: #FFF;
  border-right-color: #FFF;
}

html .toggle-light .toggle.active > label,
html .toggle-light .toggle.active > .toggle-title {
  background-color: #FFF;
  border-color: #FFF;
  color: #777;
}

html .toggle-light.toggle-simple .toggle > label:after,
html .toggle-light.toggle-simple .toggle > .toggle-title:after {
  background-color: #FFF;
}

html .toggle-light.toggle-minimal .toggle.active > label,
html .toggle-light.toggle-minimal .toggle.active > .toggle-title {
  color: #FFF;
}

.thumb-info .thumb-info-type,
.thumb-info .thumb-info-action-icon,
.thumb-info-social-icons a,
.thumbnail .zoom,
.img-thumbnail .zoom,
.thumb-info-ribbon {
  background-color: #151515;
}

html .thumb-info .thumb-info-action-icon-primary {
  background-color: #151515;
}

html .thumb-info .thumb-info-action-icon-primary i {
  color: #FFF;
}

html .thumb-info .thumb-info-action-icon-secondary {
  background-color: #e4002c;
}

html .thumb-info .thumb-info-action-icon-secondary i {
  color: #FFF;
}

html .thumb-info .thumb-info-action-icon-tertiary {
  background-color: #8a3636;
}

html .thumb-info .thumb-info-action-icon-tertiary i {
  color: #FFF;
}

html .thumb-info .thumb-info-action-icon-dark {
  background-color: #212529;
}

html .thumb-info .thumb-info-action-icon-dark i {
  color: #FFF;
}

html .thumb-info .thumb-info-action-icon-light {
  background-color: #FFF;
}

html .thumb-info .thumb-info-action-icon-light i {
  color: #777;
}

.thumb-info-ribbon:before {
  border-right-color: #040404;
  border-left-color: #040404;
}

.thumb-info.thumb-info-block-primary .thumb-info-wrapper:before {
  background: rgba(42, 42, 42, 0.9);
}

.thumb-info-floating-caption-title .thumb-info-floating-caption-type {
  background-color: #151515;
}

.inverted {
  background-color: #151515;
}

html .inverted-primary {
  background-color: #151515;
}

html .inverted-secondary {
  background-color: #e4002c;
}

html .inverted-tertiary {
  background-color: #8a3636;
}

html .inverted-dark {
  background-color: #212529;
}

html .inverted-light {
  background-color: #FFF;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background-color: #1b1b1b;
}

.owl-carousel.show-nav-title .owl-nav button[class*="owl-"],
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"].hover {
  color: #151515;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"] {
  background-color: #151515;
  border-color: #151515 #151515 #101010;
  color: #FFF;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:hover,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"].hover {
  background-color: #3d3d3d;
  border-color: #444444 #444444 #151515;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:active,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"].active {
  background-color: #171717;
  background-image: none;
  border-color: #101010 #101010 #000000;
}

.owl-carousel.nav-with-transparency:not(.nav-style-1):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"] {
  background-color: rgba(42, 42, 42, 0.4);
}

.owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
  color: #151515 !important;
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
  border-color: #151515;
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg polygon {
  fill: #151515;
  stroke: #151515;
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-prev,
.owl-carousel.nav-arrows-1 .owl-nav .owl-next {
  color: #151515;
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active > div {
  background: #151515;
}

.owl-carousel.carousel-bottom-inside-shadow .owl-stage-outer:after {
  background-image: linear-gradient(360deg, #151515 0%, transparent 100%);
}

html body .tabs .nav-tabs .nav-link,
html.dark body .tabs .nav-tabs .nav-link,
html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs .nav-link.active,
html.dark body .tabs .nav-tabs .nav-link.active {
  color: #151515;
}

html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs .nav-link:focus,
html.dark body .tabs .nav-tabs .nav-link:focus,
html body .tabs .nav-tabs .nav-link.active,
html.dark body .tabs .nav-tabs .nav-link.active,
html body .tabs .nav-tabs.nav-justified .nav-link.active,
html.dark body .tabs .nav-tabs.nav-justified .nav-link.active,
html body .tabs .nav-tabs.nav-justified .nav-link:hover,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:hover,
html body .tabs .nav-tabs.nav-justified .nav-link:focus,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:focus {
  border-top-color: #151515;
}

html body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus {
  border-bottom-color: #151515;
}

html body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:focus {
  border-left-color: #151515;
}

html body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:focus {
  border-right-color: #151515;
}

html body .tabs.tabs-simple .nav-tabs > li .nav-link.active,
html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active,
html body .tabs.tabs-simple .nav-tabs > li .nav-link.active:focus,
html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active:focus,
html body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
html body .tabs.tabs-simple .nav-tabs > li .nav-link.active:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active:hover {
  border-top-color: #151515;
  color: #151515;
}

html body .tabs-primary .nav-tabs li .nav-link,
html.dark body .tabs-primary .nav-tabs li .nav-link,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
  color: #151515;
}

html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #151515;
}

html body .tabs-primary .nav-tabs li .nav-link.active,
html.dark body .tabs-primary .nav-tabs li .nav-link.active,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link.active:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary .nav-tabs li .nav-link.active:focus,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:focus {
  border-top-color: #151515;
  color: #151515;
}

html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
  border-bottom-color: #151515;
}

html body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
  color: #151515 !important;
  border-bottom-color: #151515;
}

html body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:focus {
  border-left-color: #151515;
}

html body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:focus {
  border-right-color: #151515;
}

html body .tabs-secondary .nav-tabs li .nav-link,
html.dark body .tabs-secondary .nav-tabs li .nav-link,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
  color: #e4002c;
}

html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #e4002c;
}

html body .tabs-secondary .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:focus {
  border-top-color: #e4002c;
  color: #e4002c;
}

html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
  border-bottom-color: #e4002c;
}

html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
  color: #e4002c !important;
  border-bottom-color: #e4002c;
}

html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:focus {
  border-left-color: #e4002c;
}

html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:focus {
  border-right-color: #e4002c;
}

html body .tabs-tertiary .nav-tabs li .nav-link,
html.dark body .tabs-tertiary .nav-tabs li .nav-link,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
  color: #8a3636;
}

html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #8a3636;
}

html body .tabs-tertiary .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:focus {
  border-top-color: #8a3636;
  color: #8a3636;
}

html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
  border-bottom-color: #8a3636;
}

html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
  color: #8a3636 !important;
  border-bottom-color: #8a3636;
}

html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:focus {
  border-left-color: #8a3636;
}

html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:focus {
  border-right-color: #8a3636;
}

html body .tabs-dark .nav-tabs li .nav-link,
html.dark body .tabs-dark .nav-tabs li .nav-link,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
  color: #212529;
}

html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #212529;
}

html body .tabs-dark .nav-tabs li .nav-link.active,
html.dark body .tabs-dark .nav-tabs li .nav-link.active,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link.active:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark .nav-tabs li .nav-link.active:focus,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:focus {
  border-top-color: #212529;
  color: #212529;
}

html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
  border-bottom-color: #212529;
}

html body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
  color: #212529 !important;
  border-bottom-color: #212529;
}

html body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:focus {
  border-left-color: #212529;
}

html body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:focus {
  border-right-color: #212529;
}

html body .tabs-light .nav-tabs li .nav-link,
html.dark body .tabs-light .nav-tabs li .nav-link,
html body .tabs-light .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link,
html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
  color: #FFF;
}

html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #FFF;
}

html body .tabs-light .nav-tabs li .nav-link.active,
html.dark body .tabs-light .nav-tabs li .nav-link.active,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light .nav-tabs li .nav-link.active:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light .nav-tabs li .nav-link.active:focus,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active:focus {
  border-top-color: #FFF;
  color: #FFF;
}

html body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
  border-bottom-color: #FFF;
}

html body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
  color: #FFF !important;
  border-bottom-color: #FFF;
}

html body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:focus {
  border-left-color: #FFF;
}

html body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:focus {
  border-right-color: #FFF;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active:focus {
  border-right-color: #151515;
  border-left-color: transparent;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active:focus {
  border-right-color: transparent;
  border-left-color: #151515;
}

.list.list-icons li > [class*="fa-"]:first-child,
.list.list-icons li a:first-child > [class*="fa-"]:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .icons:first-child {
  color: #151515;
  border-color: #151515;
}

.list.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #151515;
}

.list.list-ordened li:before {
  color: #151515;
  border-color: #151515;
}

.list.list-ordened.list-ordened-style-3 li:before {
  background-color: #151515;
}

html .list-primary.list-icons li > [class*="fa-"]:first-child,
html .list-primary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-primary.list-icons li > .icons:first-child,
html .list-primary.list-icons li a:first-child > .icons:first-child {
  color: #151515;
  border-color: #151515;
}

html .list-primary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-primary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #151515;
  color: #FFF;
}

html .list-primary.list-ordened li:before {
  color: #151515;
}

html .list-primary.list-ordened.list-ordened-style-3 li:before {
  background-color: #151515;
  color: #FFF;
}

html .list-secondary.list-icons li > [class*="fa-"]:first-child,
html .list-secondary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-secondary.list-icons li > .icons:first-child,
html .list-secondary.list-icons li a:first-child > .icons:first-child {
  color: #e4002c;
  border-color: #e4002c;
}

html .list-secondary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #e4002c;
  color: #FFF;
}

html .list-secondary.list-ordened li:before {
  color: #e4002c;
}

html .list-secondary.list-ordened.list-ordened-style-3 li:before {
  background-color: #e4002c;
  color: #FFF;
}

html .list-tertiary.list-icons li > [class*="fa-"]:first-child,
html .list-tertiary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-tertiary.list-icons li > .icons:first-child,
html .list-tertiary.list-icons li a:first-child > .icons:first-child {
  color: #8a3636;
  border-color: #8a3636;
}

html .list-tertiary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #8a3636;
  color: #FFF;
}

html .list-tertiary.list-ordened li:before {
  color: #8a3636;
}

html .list-tertiary.list-ordened.list-ordened-style-3 li:before {
  background-color: #8a3636;
  color: #FFF;
}

html .list-dark.list-icons li > [class*="fa-"]:first-child,
html .list-dark.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-dark.list-icons li > .icons:first-child,
html .list-dark.list-icons li a:first-child > .icons:first-child {
  color: #212529;
  border-color: #212529;
}

html .list-dark.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-dark.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #212529;
  color: #FFF;
}

html .list-dark.list-ordened li:before {
  color: #212529;
}

html .list-dark.list-ordened.list-ordened-style-3 li:before {
  background-color: #212529;
  color: #FFF;
}

html .list-light.list-icons li > [class*="fa-"]:first-child,
html .list-light.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-light.list-icons li > .icons:first-child,
html .list-light.list-icons li a:first-child > .icons:first-child {
  color: #FFF;
  border-color: #FFF;
}

html .list-light.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-light.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #FFF;
  color: #777;
}

html .list-light.list-ordened li:before {
  color: #FFF;
}

html .list-light.list-ordened.list-ordened-style-3 li:before {
  background-color: #FFF;
  color: #777;
}

html .highlight-primary {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #151515 0);
}

html .highlight-primary.highlight-bg-opacity {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(42, 42, 42, 0.2) 0);
}

html .highlight-primary.highlight-change-text-color {
  color: #FFF;
}

html .highlight-secondary {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #e4002c 0);
}

html .highlight-secondary.highlight-bg-opacity {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(227, 97, 89, 0.2) 0);
}

html .highlight-secondary.highlight-change-text-color {
  color: #FFF;
}

html .highlight-tertiary {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #8a3636 0);
}

html .highlight-tertiary.highlight-bg-opacity {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(43, 170, 177, 0.2) 0);
}

html .highlight-tertiary.highlight-change-text-color {
  color: #FFF;
}

html .highlight-dark {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #212529 0);
}

html .highlight-dark.highlight-bg-opacity {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(33, 37, 41, 0.2) 0);
}

html .highlight-dark.highlight-change-text-color {
  color: #FFF;
}

html .highlight-light {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #FFF 0);
}

html .highlight-light.highlight-bg-opacity {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 0);
}

html .highlight-light.highlight-change-text-color {
  color: #777;
}

.parallax blockquote i.fa-quote-left {
  color: #151515;
}

section.video blockquote i.fa-quote-left {
  color: #151515;
}

.accordion .card-header a {
  color: #151515;
}

html .accordion.accordion-primary .card-header {
  background-color: #151515 !important;
}

html .accordion.accordion-primary .card-header a {
  color: #FFF;
}

html .accordion-modern-status-primary.accordion-modern-status > .card .card-header a:not(.collapsed) {
  background-color: #151515 !important;
  color: #FFF !important;
}

html .accordion-modern-status-primary.accordion-modern-status > .card:hover .card-header a.collapsed {
  color: #151515 !important;
}

html .accordion.accordion-secondary .card-header {
  background-color: #e4002c !important;
}

html .accordion.accordion-secondary .card-header a {
  color: #FFF;
}

html .accordion-modern-status-secondary.accordion-modern-status > .card .card-header a:not(.collapsed) {
  background-color: #e4002c !important;
  color: #FFF !important;
}

html .accordion-modern-status-secondary.accordion-modern-status > .card:hover .card-header a.collapsed {
  color: #e4002c !important;
}

html .accordion.accordion-tertiary .card-header {
  background-color: #8a3636 !important;
}

html .accordion.accordion-tertiary .card-header a {
  color: #FFF;
}

html .accordion-modern-status-tertiary.accordion-modern-status > .card .card-header a:not(.collapsed) {
  background-color: #8a3636 !important;
  color: #FFF !important;
}

html .accordion-modern-status-tertiary.accordion-modern-status > .card:hover .card-header a.collapsed {
  color: #8a3636 !important;
}

html .accordion.accordion-dark .card-header {
  background-color: #212529 !important;
}

html .accordion.accordion-dark .card-header a {
  color: #FFF;
}

html .accordion-modern-status-dark.accordion-modern-status > .card .card-header a:not(.collapsed) {
  background-color: #212529 !important;
  color: #FFF !important;
}

html .accordion-modern-status-dark.accordion-modern-status > .card:hover .card-header a.collapsed {
  color: #212529 !important;
}

html .accordion.accordion-light .card-header {
  background-color: #FFF !important;
}

html .accordion.accordion-light .card-header a {
  color: #777;
}

html .accordion-modern-status-light.accordion-modern-status > .card .card-header a:not(.collapsed) {
  background-color: #FFF !important;
  color: #777 !important;
}

html .accordion-modern-status-light.accordion-modern-status > .card:hover .card-header a.collapsed {
  color: #FFF !important;
}

html .divider.divider-primary [class*="fa-"],
html .divider.divider-primary .icons {
  color: #151515;
}

html .divider.divider-primary.divider-small hr {
  background: #151515;
}

html .divider.divider-secondary [class*="fa-"],
html .divider.divider-secondary .icons {
  color: #e4002c;
}

html .divider.divider-secondary.divider-small hr {
  background: #e4002c;
}

html .divider.divider-tertiary [class*="fa-"],
html .divider.divider-tertiary .icons {
  color: #8a3636;
}

html .divider.divider-tertiary.divider-small hr {
  background: #8a3636;
}

html .divider.divider-dark [class*="fa-"],
html .divider.divider-dark .icons {
  color: #212529;
}

html .divider.divider-dark.divider-small hr {
  background: #212529;
}

html .divider.divider-light [class*="fa-"],
html .divider.divider-light .icons {
  color: #FFF;
}

html .divider.divider-light.divider-small hr {
  background: #FFF;
}

html .divider.divider-style-2.divider-primary [class*="fa-"],
html .divider.divider-style-2.divider-primary .icons {
  background: #151515;
  color: #FFF;
}

html .divider.divider-style-2.divider-secondary [class*="fa-"],
html .divider.divider-style-2.divider-secondary .icons {
  background: #e4002c;
  color: #FFF;
}

html .divider.divider-style-2.divider-tertiary [class*="fa-"],
html .divider.divider-style-2.divider-tertiary .icons {
  background: #8a3636;
  color: #FFF;
}

html .divider.divider-style-2.divider-dark [class*="fa-"],
html .divider.divider-style-2.divider-dark .icons {
  background: #212529;
  color: #FFF;
}

html .divider.divider-style-2.divider-light [class*="fa-"],
html .divider.divider-style-2.divider-light .icons {
  background: #FFF;
  color: #777;
}

html .divider.divider-style-3.divider-primary [class*="fa-"],
html .divider.divider-style-3.divider-primary .icons {
  border-color: #151515;
}

html .divider.divider-style-3.divider-secondary [class*="fa-"],
html .divider.divider-style-3.divider-secondary .icons {
  border-color: #e4002c;
}

html .divider.divider-style-3.divider-tertiary [class*="fa-"],
html .divider.divider-style-3.divider-tertiary .icons {
  border-color: #8a3636;
}

html .divider.divider-style-3.divider-dark [class*="fa-"],
html .divider.divider-style-3.divider-dark .icons {
  border-color: #212529;
}

html .divider.divider-style-3.divider-light [class*="fa-"],
html .divider.divider-style-3.divider-light .icons {
  border-color: #FFF;
}

#header .tip {
  background: #151515;
  color: #FFF;
}

#header .tip:before {
  border-right-color: #151515;
  border-left-color: transparent;
}

html #header .tip-primary {
  background: #151515 !important;
  color: #FFF !important;
}

html #header .tip-primary:before {
  border-right-color: #151515 !important;
}

html #header .tip-secondary {
  background: #e4002c !important;
  color: #FFF !important;
}

html #header .tip-secondary:before {
  border-right-color: #e4002c !important;
}

html #header .tip-tertiary {
  background: #8a3636 !important;
  color: #FFF !important;
}

html #header .tip-tertiary:before {
  border-right-color: #8a3636 !important;
}

html #header .tip-dark {
  background: #212529 !important;
  color: #FFF !important;
}

html #header .tip-dark:before {
  border-right-color: #212529 !important;
}

html #header .tip-light {
  background: #FFF !important;
  color: #777 !important;
}

html #header .tip-light:before {
  border-right-color: #FFF !important;
}

html[dir="rtl"] #header .tip:before {
  border-left-color: #151515;
}

html[dir="rtl"] #header .tip.skin:before {
  border-left-color: #151515;
}

html[dir="rtl"] #header .tip-primary:before {
  border-left-color: #151515 !important;
  border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-secondary:before {
  border-left-color: #e4002c !important;
  border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-tertiary:before {
  border-left-color: #8a3636 !important;
  border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-dark:before {
  border-left-color: #212529 !important;
  border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-light:before {
  border-left-color: #FFF !important;
  border-right-color: transparent !important;
}

#header .header-btn-collapse-nav {
  background: #151515;
}

@media (min-width: 992px) {
  #header .header-nav-main nav > ul > li > a {
    color: #151515;
  }

  #header .header-nav-main nav > ul > li.open > a,
  #header .header-nav-main nav > ul > li:hover > a {
    background: #151515;
  }

  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    border-top-color: #151515;
  }

  #header .header-nav-main nav > ul > li.dropdown.open > a:before,
  #header .header-nav-main nav > ul > li.dropdown:hover > a:before {
    border-bottom-color: #151515;
  }

  #header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
    border-top-color: #151515;
  }

  #header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu li a {
    color: #151515;
  }

  #header .header-nav-main nav > ul:not(:hover) > li > a.active {
    background-color: #151515;
    color: #FFF;
  }

  #header .header-nav.header-nav-stripe nav > ul > li:hover > a,
  #header .header-nav.header-nav-stripe nav > ul > li.open > a {
    background-color: #151515;
  }

  #header .header-nav.header-nav-stripe nav > ul:not(:hover) > li > a.active {
    background-color: #151515;
  }

  #header .header-nav.header-nav-links.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar-thumb {
    background: #151515 !important;
  }

  #header .header-nav.header-nav-line nav > ul:not(:hover) li > a.active:before {
    background: #151515;
  }

  #header .header-nav-main.header-nav-main-slide nav > ul > li.open > span > a,
  #header .header-nav-main.header-nav-main-slide nav > ul > li:hover > span > a {
    color: #151515;
  }

  #header .header-nav-main.header-nav-main-slide nav > ul:not(:hover) > li > span > a.active {
    color: #151515;
  }

  html.side-header #header.side-header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
  html.side-header #header.side-header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
    border-left-color: #151515;
  }

  html.side-header-right #header.side-header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
  html.side-header-right #header.side-header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
    border-right-color: #151515;
  }
}

@media (min-width: 992px) {
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu {
    background-color: #151515;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
    color: #FFF;
    border-bottom-color: #222222;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li:hover > a {
    background: #323232;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:before,
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:before {
    border-bottom-color: #151515;
    background: #151515;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-primary .dropdown-mega-sub-title {
    color: #FFF;
    opacity: 0.7;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
    background: #151515;
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
    background: #151515;
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover {
    color: #151515;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover:before {
    background-color: #151515;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
    color: #151515;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:before {
    background: #151515;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
    color: #151515;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active:before {
    background-color: #151515;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a {
    color: #151515;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus,
  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover {
    color: #151515;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
    color: #151515;
  }

  html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
    color: #151515;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu {
    background-color: #e4002c;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
    color: #FFF;
    border-bottom-color: #e1554c;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li:hover > a {
    background: #e56d66;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.open > a:before,
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a:before {
    border-bottom-color: #e4002c;
    background: #e4002c;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-secondary .dropdown-mega-sub-title {
    color: #FFF;
    opacity: 0.7;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
    background: #e4002c;
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
    background: #e4002c;
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover {
    color: #e4002c;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover:before {
    background-color: #e4002c;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
    color: #e4002c;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.open > a:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a:before {
    background: #e4002c;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
    color: #e4002c;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active:before {
    background-color: #e4002c;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a {
    color: #e4002c;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus,
  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover {
    color: #e4002c;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
    color: #e4002c;
  }

  html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
    color: #e4002c;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu {
    background-color: #8a3636;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
    color: #FFF;
    border-bottom-color: #289ea5;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li:hover > a {
    background: #2eb6bd;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.open > a:before,
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a:before {
    border-bottom-color: #8a3636;
    background: #8a3636;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-tertiary .dropdown-mega-sub-title {
    color: #FFF;
    opacity: 0.7;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
    background: #8a3636;
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
    background: #8a3636;
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover {
    color: #8a3636;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover:before {
    background-color: #8a3636;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
    color: #8a3636;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.open > a:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a:before {
    background: #8a3636;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
    color: #8a3636;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active:before {
    background-color: #8a3636;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a {
    color: #8a3636;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus,
  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover {
    color: #8a3636;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
    color: #8a3636;
  }

  html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
    color: #8a3636;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu {
    background-color: #212529;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
    color: #FFF;
    border-bottom-color: #1a1d21;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li:hover > a {
    background: #282d31;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.open > a:before,
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a:before {
    border-bottom-color: #212529;
    background: #212529;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-dark .dropdown-mega-sub-title {
    color: #FFF;
    opacity: 0.7;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
    background: #212529;
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
    color: #FFF;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
    background: #212529;
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover {
    color: #212529;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover:before {
    background-color: #212529;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
    color: #212529;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.open > a:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a:before {
    background: #212529;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
    color: #212529;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active:before {
    background-color: #212529;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a {
    color: #212529;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus,
  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover {
    color: #212529;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
    color: #212529;
  }

  html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
    color: #212529;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu {
    background-color: #FFF;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li a {
    color: #777;
    border-bottom-color: #f7f7f7;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li:hover > a {
    background: #ffffff;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.open > a:before,
  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a:before {
    border-bottom-color: #FFF;
    background: #FFF;
  }

  html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-light .dropdown-mega-sub-title {
    color: #777;
    opacity: 0.7;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
    background: #FFF;
    color: #777;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li a {
    color: #777;
  }

  html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
    background: #FFF;
    color: #777;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover {
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover:before {
    background-color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.open > a:before,
  html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a:before {
    background: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
    color: #FFF;
  }

  html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active:before {
    background-color: #FFF;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a {
    color: #FFF;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus,
  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover {
    color: #FFF;
  }

  html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
    color: #FFF;
  }

  html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
    color: #FFF;
  }
}

@media (min-width: 992px) {
  html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul > li:hover > a,
  html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul > li .wrapper-items-cloned:hover > a {
    color: #151515 !important;
  }

  html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul:not(:hover) > li > a.active,
  html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul:not(:hover) > li .wrapper-items-cloned > a.active {
    color: #151515 !important;
  }
}

@media (max-width: 991px) {
  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a {
    color: #151515;
  }

  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a:active {
    color: #151515;
  }

  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active {
    background: #151515;
  }

  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active:focus,
  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active:hover {
    background: #151515;
  }

  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
    background: #151515;
  }

  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:focus,
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:hover {
    background: #151515;
  }

  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
    color: #151515 !important;
  }

  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav::-webkit-scrollbar-thumb {
    background-color: #151515;
  }

  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a.active {
    color: #151515 !important;
  }

  html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li:hover > a {
    color: #151515 !important;
  }
}

@media (max-width: 991px) {
  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a {
    color: #151515;
  }

  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a.active {
    background: #151515 !important;
    color: #FFF !important;
  }

  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary > a {
    color: #e4002c;
  }

  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary > a.active {
    background: #e4002c !important;
    color: #FFF !important;
  }

  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary > a {
    color: #8a3636;
  }

  html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary > a.active {
    background: #8a3636 !important;
    color: #FFF !important;
  }
}

html #header .header-top.header-top-primary {
  background: #151515;
  border-top-color: #1d1d1d;
}

html #header .header-top.header-top-primary .header-nav-top ul > li.open > a {
  background: #373737;
}

html #header .header-top.header-top-primary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:focus {
  background: #373737;
}

html #header .header-top.header-top-primary .dropdown-menu {
  background: #373737;
  border-color: #373737;
}

html #header .header-top.header-top-primary .dropdown-menu a:hover,
html #header .header-top.header-top-primary .dropdown-menu a:focus {
  background: #444444 !important;
}

html #header .header-top.header-top-secondary {
  background: #e4002c;
  border-top-color: #df4c43;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a {
  background: #e7766f;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:focus {
  background: #e7766f;
}

html #header .header-top.header-top-secondary .dropdown-menu {
  background: #e7766f;
  border-color: #e7766f;
}

html #header .header-top.header-top-secondary .dropdown-menu a:hover,
html #header .header-top.header-top-secondary .dropdown-menu a:focus {
  background: #ea8b85 !important;
}

html #header .header-top.header-top-tertiary {
  background: #8a3636;
  border-top-color: #26969c;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a {
  background: #30bec6;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:focus {
  background: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu {
  background: #30bec6;
  border-color: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu a:hover,
html #header .header-top.header-top-tertiary .dropdown-menu a:focus {
  background: #3fc9d0 !important;
}

html #header .header-top.header-top-dark {
  background: #212529;
  border-top-color: #16181b;
}

html #header .header-top.header-top-dark .header-nav-top ul > li.open > a {
  background: #2c3237;
}

html #header .header-top.header-top-dark .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:focus {
  background: #2c3237;
}

html #header .header-top.header-top-dark .dropdown-menu {
  background: #2c3237;
  border-color: #2c3237;
}

html #header .header-top.header-top-dark .dropdown-menu a:hover,
html #header .header-top.header-top-dark .dropdown-menu a:focus {
  background: #383f45 !important;
}

html #header .header-top.header-top-light {
  background: #FFF;
  border-top-color: #f2f2f2;
}

html #header .header-top.header-top-light .header-nav-top ul > li.open > a {
  background: #ffffff;
}

html #header .header-top.header-top-light .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:focus {
  background: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu {
  background: #ffffff;
  border-color: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu a:hover,
html #header .header-top.header-top-light .dropdown-menu a:focus {
  background: #ffffff !important;
}

@media (min-width: 992px) {
  html #header .header-nav-bar-primary {
    background: #151515 !important;
    color: #FFF !important;
  }

  html #header .header-nav-bar-secondary {
    background: #e4002c !important;
    color: #FFF !important;
  }

  html #header .header-nav-bar-tertiary {
    background: #8a3636 !important;
    color: #FFF !important;
  }

  html #header .header-nav-bar-dark {
    background: #212529 !important;
    color: #FFF !important;
  }

  html #header .header-nav-bar-light {
    background: #FFF !important;
    color: #777 !important;
  }
}

@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > a,
  #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    color: #151515;
  }

  #header .header-nav-main.header-nav-main-light nav > ul > li > a.active {
    color: #151515;
  }
}

#header .header-nav-features .header-nav-features-search-reveal-big-search .form-control {
  border-bottom-color: #151515;
}

.testimonial blockquote {
  background: #373737;
}

.testimonial .testimonial-arrow-down {
  border-top-color: #373737;
}

html .testimonial-primary blockquote {
  background: #373737;
}

html .testimonial-primary .testimonial-arrow-down {
  border-top-color: #373737;
}

html .testimonial-primary.testimonial-style-7 {
  background: #373737;
}

html .testimonial-quotes-primary blockquote:before,
html .testimonial-quotes-primary blockquote:after {
  color: #151515 !important;
}

html .testimonial-secondary blockquote {
  background: #e7766f;
}

html .testimonial-secondary .testimonial-arrow-down {
  border-top-color: #e7766f;
}

html .testimonial-secondary.testimonial-style-7 {
  background: #e7766f;
}

html .testimonial-quotes-secondary blockquote:before,
html .testimonial-quotes-secondary blockquote:after {
  color: #151515 !important;
}

html .testimonial-tertiary blockquote {
  background: #30bec6;
}

html .testimonial-tertiary .testimonial-arrow-down {
  border-top-color: #30bec6;
}

html .testimonial-tertiary.testimonial-style-7 {
  background: #30bec6;
}

html .testimonial-quotes-tertiary blockquote:before,
html .testimonial-quotes-tertiary blockquote:after {
  color: #151515 !important;
}

html .testimonial-dark blockquote {
  background: #2c3237;
}

html .testimonial-dark .testimonial-arrow-down {
  border-top-color: #2c3237;
}

html .testimonial-dark.testimonial-style-7 {
  background: #2c3237;
}

html .testimonial-quotes-dark blockquote:before,
html .testimonial-quotes-dark blockquote:after {
  color: #151515 !important;
}

html .testimonial-light blockquote {
  background: #ffffff;
}

html .testimonial-light .testimonial-arrow-down {
  border-top-color: #ffffff;
}

html .testimonial-light.testimonial-style-7 {
  background: #ffffff;
}

html .testimonial-quotes-light blockquote:before,
html .testimonial-quotes-light blockquote:after {
  color: #151515 !important;
}

.circular-bar.only-icon [class*="fa-"],
.circular-bar.only-icon .icons {
  color: #151515;
}

.form-control.bg-primary,
.form-control.bg-color-primary {
  color: #FFF;
  border-color: #151515;
}

.form-control.bg-primary::-webkit-input-placeholder,
.form-control.bg-color-primary::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary::-moz-placeholder,
.form-control.bg-color-primary::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary:-ms-input-placeholder,
.form-control.bg-color-primary:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary:-moz-placeholder,
.form-control.bg-color-primary:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-primary blockquote {
  background: #373737;
}

.form-control .testimonial-primary .testimonial-arrow-down {
  border-top-color: #373737;
}

.form-control .testimonial-primary.testimonial-style-7 {
  background: #373737;
}

.form-control .testimonial-quotes-primary blockquote:before,
.form-control .testimonial-quotes-primary blockquote:after {
  color: #151515 !important;
}

.form-control.bg-secondary,
.form-control.bg-color-secondary {
  color: #FFF;
  border-color: #e4002c;
}

.form-control.bg-secondary::-webkit-input-placeholder,
.form-control.bg-color-secondary::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-secondary::-moz-placeholder,
.form-control.bg-color-secondary::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-secondary:-ms-input-placeholder,
.form-control.bg-color-secondary:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-secondary:-moz-placeholder,
.form-control.bg-color-secondary:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-secondary blockquote {
  background: #e7766f;
}

.form-control .testimonial-secondary .testimonial-arrow-down {
  border-top-color: #e7766f;
}

.form-control .testimonial-secondary.testimonial-style-7 {
  background: #e7766f;
}

.form-control .testimonial-quotes-secondary blockquote:before,
.form-control .testimonial-quotes-secondary blockquote:after {
  color: #151515 !important;
}

.form-control.bg-tertiary,
.form-control.bg-color-tertiary {
  color: #FFF;
  border-color: #8a3636;
}

.form-control.bg-tertiary::-webkit-input-placeholder,
.form-control.bg-color-tertiary::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary::-moz-placeholder,
.form-control.bg-color-tertiary::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary:-ms-input-placeholder,
.form-control.bg-color-tertiary:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary:-moz-placeholder,
.form-control.bg-color-tertiary:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-tertiary blockquote {
  background: #30bec6;
}

.form-control .testimonial-tertiary .testimonial-arrow-down {
  border-top-color: #30bec6;
}

.form-control .testimonial-tertiary.testimonial-style-7 {
  background: #30bec6;
}

.form-control .testimonial-quotes-tertiary blockquote:before,
.form-control .testimonial-quotes-tertiary blockquote:after {
  color: #151515 !important;
}

.form-control.bg-dark,
.form-control.bg-color-dark {
  color: #FFF;
  border-color: #212529;
}

.form-control.bg-dark::-webkit-input-placeholder,
.form-control.bg-color-dark::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark::-moz-placeholder,
.form-control.bg-color-dark::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark:-ms-input-placeholder,
.form-control.bg-color-dark:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark:-moz-placeholder,
.form-control.bg-color-dark:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-dark blockquote {
  background: #2c3237;
}

.form-control .testimonial-dark .testimonial-arrow-down {
  border-top-color: #2c3237;
}

.form-control .testimonial-dark.testimonial-style-7 {
  background: #2c3237;
}

.form-control .testimonial-quotes-dark blockquote:before,
.form-control .testimonial-quotes-dark blockquote:after {
  color: #151515 !important;
}

.form-control.bg-light,
.form-control.bg-color-light {
  color: #777;
  border-color: #FFF;
}

.form-control.bg-light::-webkit-input-placeholder,
.form-control.bg-color-light::-webkit-input-placeholder {
  color: #5e5e5e;
}

.form-control.bg-light::-moz-placeholder,
.form-control.bg-color-light::-moz-placeholder {
  color: #5e5e5e;
}

.form-control.bg-light:-ms-input-placeholder,
.form-control.bg-color-light:-ms-input-placeholder {
  color: #5e5e5e;
}

.form-control.bg-light:-moz-placeholder,
.form-control.bg-color-light:-moz-placeholder {
  color: #5e5e5e;
}

.form-control .testimonial-light blockquote {
  background: #ffffff;
}

.form-control .testimonial-light .testimonial-arrow-down {
  border-top-color: #ffffff;
}

.form-control .testimonial-light.testimonial-style-7 {
  background: #ffffff;
}

.form-control .testimonial-quotes-light blockquote:before,
.form-control .testimonial-quotes-light blockquote:after {
  color: #151515 !important;
}

.form-range::-webkit-slider-thumb {
  background-color: #151515;
}

.form-range::-webkit-slider-thumb:active {
  background-color: rgba(42, 42, 42, 0.25);
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(42, 42, 42, 0.25);
}

.form-switch .form-check-input:checked {
  background-color: #151515;
  border-color: #151515 !important;
}

html .counters .counter-primary [class*="fa-"],
html .counters .counter-primary .icons,
html .counters .counter-primary strong {
  color: #151515;
}

html .counters .counter-secondary [class*="fa-"],
html .counters .counter-secondary .icons,
html .counters .counter-secondary strong {
  color: #e4002c;
}

html .counters .counter-tertiary [class*="fa-"],
html .counters .counter-tertiary .icons,
html .counters .counter-tertiary strong {
  color: #8a3636;
}

html .counters .counter-dark [class*="fa-"],
html .counters .counter-dark .icons,
html .counters .counter-dark strong {
  color: #212529;
}

html .counters .counter-light [class*="fa-"],
html .counters .counter-light .icons,
html .counters .counter-light strong {
  color: #FFF;
}

html .countdown.countdown-primary > span > span {
  color: #151515;
}

html .countdown.countdown-secondary > span > span {
  color: #e4002c;
}

html .countdown.countdown-tertiary > span > span {
  color: #8a3636;
}

html .countdown.countdown-dark > span > span {
  color: #212529;
}

html .countdown.countdown-light > span > span {
  color: #FFF;
}

html .image-hotspot-primary strong {
  color: #FFF !important;
}

html .image-hotspot-primary .circle {
  background: #151515 !important;
}

html .image-hotspot-primary .ring {
  border-color: rgba(42, 42, 42, 0.9);
}

html .image-hotspot-secondary strong {
  color: #FFF !important;
}

html .image-hotspot-secondary .circle {
  background: #e4002c !important;
}

html .image-hotspot-secondary .ring {
  border-color: rgba(227, 97, 89, 0.9);
}

html .image-hotspot-tertiary strong {
  color: #FFF !important;
}

html .image-hotspot-tertiary .circle {
  background: #8a3636 !important;
}

html .image-hotspot-tertiary .ring {
  border-color: rgba(43, 170, 177, 0.9);
}

html .image-hotspot-dark strong {
  color: #FFF !important;
}

html .image-hotspot-dark .circle {
  background: #212529 !important;
}

html .image-hotspot-dark .ring {
  border-color: rgba(33, 37, 41, 0.9);
}

html .image-hotspot-light strong {
  color: #777 !important;
}

html .image-hotspot-light .circle {
  background: #FFF !important;
}

html .image-hotspot-light .ring {
  border-color: rgba(255, 255, 255, 0.9);
}

.icon-featured {
  background-color: #151515;
}

html .featured-box-primary .icon-featured {
  background-color: #151515;
}

html .featured-box-primary h4 {
  color: #151515;
}

html .featured-box-primary .box-content {
  border-top-color: #151515;
}

html .featured-box-primary .box-content-border-bottom {
  border-bottom-color: #151515;
}

html .featured-box-secondary .icon-featured {
  background-color: #e4002c;
}

html .featured-box-secondary h4 {
  color: #e4002c;
}

html .featured-box-secondary .box-content {
  border-top-color: #e4002c;
}

html .featured-box-secondary .box-content-border-bottom {
  border-bottom-color: #e4002c;
}

html .featured-box-tertiary .icon-featured {
  background-color: #8a3636;
}

html .featured-box-tertiary h4 {
  color: #8a3636;
}

html .featured-box-tertiary .box-content {
  border-top-color: #8a3636;
}

html .featured-box-tertiary .box-content-border-bottom {
  border-bottom-color: #8a3636;
}

html .featured-box-dark .icon-featured {
  background-color: #212529;
}

html .featured-box-dark h4 {
  color: #212529;
}

html .featured-box-dark .box-content {
  border-top-color: #212529;
}

html .featured-box-dark .box-content-border-bottom {
  border-bottom-color: #212529;
}

html .featured-box-light .icon-featured {
  background-color: #FFF;
}

html .featured-box-light h4 {
  color: #FFF;
}

html .featured-box-light .box-content {
  border-top-color: #FFF;
}

html .featured-box-light .box-content-border-bottom {
  border-bottom-color: #FFF;
}

html .featured-boxes-style-3 .featured-box.featured-box-primary .icon-featured {
  border-color: #151515;
  color: #151515;
}

html .featured-boxes-style-3 .featured-box.featured-box-secondary .icon-featured {
  border-color: #e4002c;
  color: #e4002c;
}

html .featured-boxes-style-3 .featured-box.featured-box-tertiary .icon-featured {
  border-color: #8a3636;
  color: #8a3636;
}

html .featured-boxes-style-3 .featured-box.featured-box-dark .icon-featured {
  border-color: #212529;
  color: #212529;
}

html .featured-boxes-style-3 .featured-box.featured-box-light .icon-featured {
  border-color: #FFF;
  color: #FFF;
}

html .featured-boxes-style-4 .featured-box.featured-box-primary .icon-featured {
  border-color: #151515;
  color: #151515;
}

html .featured-boxes-style-4 .featured-box.featured-box-secondary .icon-featured {
  border-color: #e4002c;
  color: #e4002c;
}

html .featured-boxes-style-4 .featured-box.featured-box-tertiary .icon-featured {
  border-color: #8a3636;
  color: #8a3636;
}

html .featured-boxes-style-4 .featured-box.featured-box-dark .icon-featured {
  border-color: #212529;
  color: #212529;
}

html .featured-boxes-style-4 .featured-box.featured-box-light .icon-featured {
  border-color: #FFF;
  color: #FFF;
}

html .featured-boxes-style-5 .featured-box.featured-box-primary .icon-featured {
  color: #151515;
}

html .featured-boxes-style-5 .featured-box.featured-box-secondary .icon-featured {
  color: #e4002c;
}

html .featured-boxes-style-5 .featured-box.featured-box-tertiary .icon-featured {
  color: #8a3636;
}

html .featured-boxes-style-5 .featured-box.featured-box-dark .icon-featured {
  color: #212529;
}

html .featured-boxes-style-5 .featured-box.featured-box-light .icon-featured {
  color: #FFF;
}

html .featured-boxes-style-6 .featured-box.featured-box-primary .icon-featured {
  color: #151515;
}

html .featured-boxes-style-6 .featured-box.featured-box-secondary .icon-featured {
  color: #e4002c;
}

html .featured-boxes-style-6 .featured-box.featured-box-tertiary .icon-featured {
  color: #8a3636;
}

html .featured-boxes-style-6 .featured-box.featured-box-dark .icon-featured {
  color: #212529;
}

html .featured-boxes-style-6 .featured-box.featured-box-light .icon-featured {
  color: #FFF;
}

html .featured-boxes-style-8 .featured-box.featured-box-primary .icon-featured {
  color: #151515;
}

html .featured-boxes-style-8 .featured-box.featured-box-secondary .icon-featured {
  color: #e4002c;
}

html .featured-boxes-style-8 .featured-box.featured-box-tertiary .icon-featured {
  color: #8a3636;
}

html .featured-boxes-style-8 .featured-box.featured-box-dark .icon-featured {
  color: #212529;
}

html .featured-boxes-style-8 .featured-box.featured-box-light .icon-featured {
  color: #FFF;
}

.featured-boxes-modern-style-primary .featured-box {
  background: #151515;
  background: linear-gradient(135deg, #151515 0%, #383f48 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151515', endColorstr='#383f48', GradientType=1);
}

.featured-boxes-modern-style-secondary .featured-box {
  background: #e4002c;
  background: linear-gradient(135deg, #e4002c 0%, #383f48 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4002c', endColorstr='#383f48', GradientType=1);
}

.featured-boxes-modern-style-tertiary .featured-box {
  background: #8a3636;
  background: linear-gradient(135deg, #8a3636 0%, #383f48 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a3636', endColorstr='#383f48', GradientType=1);
}

html .featured-box-effect-2.featured-box-primary .icon-featured:after {
  box-shadow: 0 0 0 3px #151515;
}

html .featured-box-effect-2.featured-box-secondary .icon-featured:after {
  box-shadow: 0 0 0 3px #e4002c;
}

html .featured-box-effect-2.featured-box-tertiary .icon-featured:after {
  box-shadow: 0 0 0 3px #8a3636;
}

html .featured-box-effect-2.featured-box-dark .icon-featured:after {
  box-shadow: 0 0 0 3px #212529;
}

html .featured-box-effect-2.featured-box-light .icon-featured:after {
  box-shadow: 0 0 0 3px #FFF;
}

html .featured-box-effect-3.featured-box-primary .icon-featured:after {
  box-shadow: 0 0 0 10px #151515;
}

html .featured-box-effect-3.featured-box-primary:hover .icon-featured {
  background: #151515 !important;
}

html .featured-box-effect-3.featured-box-secondary .icon-featured:after {
  box-shadow: 0 0 0 10px #e4002c;
}

html .featured-box-effect-3.featured-box-secondary:hover .icon-featured {
  background: #e4002c !important;
}

html .featured-box-effect-3.featured-box-tertiary .icon-featured:after {
  box-shadow: 0 0 0 10px #8a3636;
}

html .featured-box-effect-3.featured-box-tertiary:hover .icon-featured {
  background: #8a3636 !important;
}

html .featured-box-effect-3.featured-box-dark .icon-featured:after {
  box-shadow: 0 0 0 10px #212529;
}

html .featured-box-effect-3.featured-box-dark:hover .icon-featured {
  background: #212529 !important;
}

html .featured-box-effect-3.featured-box-light .icon-featured:after {
  box-shadow: 0 0 0 10px #FFF;
}

html .featured-box-effect-3.featured-box-light:hover .icon-featured {
  background: #FFF !important;
}

.feature-box .feature-box-icon {
  background-color: #151515;
}

html .feature-box-primary .feature-box-icon {
  background-color: #151515;
}

html .feature-box-secondary .feature-box-icon {
  background-color: #e4002c;
}

html .feature-box-tertiary .feature-box-icon {
  background-color: #8a3636;
}

html .feature-box-dark .feature-box-icon {
  background-color: #212529;
}

html .feature-box-light .feature-box-icon {
  background-color: #FFF;
}

.feature-box.feature-box-style-2 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-primary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-2 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-secondary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-2 .feature-box-icon .icons {
  color: #e4002c;
}

html .feature-box-tertiary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-2 .feature-box-icon .icons {
  color: #8a3636;
}

html .feature-box-dark.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-2 .feature-box-icon .icons {
  color: #212529;
}

html .feature-box-light.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-2 .feature-box-icon .icons {
  color: #FFF;
}

.feature-box.feature-box-style-3 .feature-box-icon {
  border-color: #151515;
}

.feature-box.feature-box-style-3 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-3 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon {
  border-color: #151515;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-3 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon {
  border-color: #e4002c;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-3 .feature-box-icon .icons {
  color: #e4002c;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon {
  border-color: #8a3636;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-3 .feature-box-icon .icons {
  color: #8a3636;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon {
  border-color: #212529;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-3 .feature-box-icon .icons {
  color: #212529;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon {
  border-color: #FFF;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-3 .feature-box-icon .icons {
  color: #FFF;
}

.feature-box.feature-box-style-4 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-4 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-primary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-4 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-secondary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-4 .feature-box-icon .icons {
  color: #e4002c;
}

html .feature-box-tertiary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-4 .feature-box-icon .icons {
  color: #8a3636;
}

html .feature-box-dark.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-4 .feature-box-icon .icons {
  color: #212529;
}

html .feature-box-light.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-4 .feature-box-icon .icons {
  color: #FFF;
}

.feature-box.feature-box-style-5 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-5 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-primary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-5 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-secondary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-5 .feature-box-icon .icons {
  color: #e4002c;
}

html .feature-box-tertiary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-5 .feature-box-icon .icons {
  color: #8a3636;
}

html .feature-box-dark.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-5 .feature-box-icon .icons {
  color: #212529;
}

html .feature-box-light.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-5 .feature-box-icon .icons {
  color: #FFF;
}

html .feature-box-primary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-6 .feature-box-icon .icons {
  color: #151515;
}

html .feature-box-secondary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-6 .feature-box-icon .icons {
  color: #e4002c;
}

html .feature-box-tertiary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-6 .feature-box-icon .icons {
  color: #8a3636;
}

html .feature-box-dark.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-6 .feature-box-icon .icons {
  color: #212529;
}

html .feature-box-light.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-6 .feature-box-icon .icons {
  color: #FFF;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(1) {
  background-color: #101010;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(2) {
  background-color: #1d1d1d;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(3) {
  background-color: #151515;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(4) {
  background-color: #373737;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(5) {
  background-color: #444444;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(6) {
  background-color: #505050;
}

html .featured-boxes-full .featured-box-full-primary {
  background-color: #151515;
  color: #FFF;
}

html .featured-boxes-full .featured-box-full-secondary {
  background-color: #e4002c;
  color: #FFF;
}

html .featured-boxes-full .featured-box-full-tertiary {
  background-color: #8a3636;
  color: #FFF;
}

html .featured-boxes-full .featured-box-full-dark {
  background-color: #212529;
  color: #FFF;
}

html .featured-boxes-full .featured-box-full-light {
  background-color: #FFF;
  color: #777;
}

.pricing-table .most-popular {
  border-color: #151515;
}

.pricing-table .most-popular h3 {
  background-color: #151515;
}

.pricing-table.princig-table-flat .plan h3 {
  background-color: #151515;
}

.pricing-table.princig-table-flat .plan h3 span {
  background: #151515;
}

html .call-to-action.featured.featured-primary {
  border-top-color: #151515;
}

html .call-to-action.call-to-action-primary {
  background: #151515;
}

html .call-to-action.with-full-borders-primary {
  border-color: #151515;
}

html .call-to-action.call-to-action-primary {
  background: #151515;
}

html .call-to-action.call-to-action-primary h3,
html .call-to-action.call-to-action-primary p {
  color: #FFF;
}

html .call-to-action.featured.featured-secondary {
  border-top-color: #e4002c;
}

html .call-to-action.call-to-action-secondary {
  background: #e4002c;
}

html .call-to-action.with-full-borders-secondary {
  border-color: #e4002c;
}

html .call-to-action.call-to-action-secondary {
  background: #e4002c;
}

html .call-to-action.call-to-action-secondary h3,
html .call-to-action.call-to-action-secondary p {
  color: #FFF;
}

html .call-to-action.featured.featured-tertiary {
  border-top-color: #8a3636;
}

html .call-to-action.call-to-action-tertiary {
  background: #8a3636;
}

html .call-to-action.with-full-borders-tertiary {
  border-color: #8a3636;
}

html .call-to-action.call-to-action-tertiary {
  background: #8a3636;
}

html .call-to-action.call-to-action-tertiary h3,
html .call-to-action.call-to-action-tertiary p {
  color: #FFF;
}

html .call-to-action.featured.featured-dark {
  border-top-color: #212529;
}

html .call-to-action.call-to-action-dark {
  background: #212529;
}

html .call-to-action.with-full-borders-dark {
  border-color: #212529;
}

html .call-to-action.call-to-action-dark {
  background: #212529;
}

html .call-to-action.call-to-action-dark h3,
html .call-to-action.call-to-action-dark p {
  color: #FFF;
}

html .call-to-action.featured.featured-light {
  border-top-color: #FFF;
}

html .call-to-action.call-to-action-light {
  background: #FFF;
}

html .call-to-action.with-full-borders-light {
  border-color: #FFF;
}

html .call-to-action.call-to-action-light {
  background: #FFF;
}

html .call-to-action.call-to-action-light h3,
html .call-to-action.call-to-action-light p {
  color: #777;
}

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
  background: #151515;
  box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #151515;
}

ul.history li .featured-box .box-content {
  border-top-color: #151515;
}

.portfolio-list .portfolio-item.portfolio-item-new:after {
  background: linear-gradient(135deg, #151515 0%, #e4002c 80%);
}

.recent-posts .date .month,
article.post .post-date .month {
  background-color: #151515;
}

.recent-posts .date .day,
article.post .post-date .day {
  color: #151515;
}

.slider .tp-bannertimer {
  background-color: #151515;
}

.home-concept strong {
  color: #151515;
}

html .home-intro-primary {
  background: #151515;
}

html .home-intro-secondary {
  background: #e4002c;
}

html .home-intro-tertiary {
  background: #8a3636;
}

html .home-intro-dark {
  background: #212529;
}

html .home-intro-light {
  background: #FFF;
}

html .slide-overlay-primary .slotholder:after {
  background-color: #151515 !important;
}

html .slider-container .tp-caption-overlay-primary {
  background: #151515;
}

html .slider-container .tp-caption-overlay-opacity-primary {
  background: rgba(42, 42, 42, 0.4);
}

html .tp-bullets.bullets-color-primary .tp-bullet:hover,
html .tp-bullets.bullets-color-primary .tp-bullet.selected {
  background: #151515;
}

html .slide-overlay-secondary .slotholder:after {
  background-color: #e4002c !important;
}

html .slider-container .tp-caption-overlay-secondary {
  background: #e4002c;
}

html .slider-container .tp-caption-overlay-opacity-secondary {
  background: rgba(227, 97, 89, 0.4);
}

html .tp-bullets.bullets-color-secondary .tp-bullet:hover,
html .tp-bullets.bullets-color-secondary .tp-bullet.selected {
  background: #e4002c;
}

html .slide-overlay-tertiary .slotholder:after {
  background-color: #8a3636 !important;
}

html .slider-container .tp-caption-overlay-tertiary {
  background: #8a3636;
}

html .slider-container .tp-caption-overlay-opacity-tertiary {
  background: rgba(43, 170, 177, 0.4);
}

html .tp-bullets.bullets-color-tertiary .tp-bullet:hover,
html .tp-bullets.bullets-color-tertiary .tp-bullet.selected {
  background: #8a3636;
}

html .slide-overlay-dark .slotholder:after {
  background-color: #212529 !important;
}

html .slider-container .tp-caption-overlay-dark {
  background: #212529;
}

html .slider-container .tp-caption-overlay-opacity-dark {
  background: rgba(33, 37, 41, 0.4);
}

html .tp-bullets.bullets-color-dark .tp-bullet:hover,
html .tp-bullets.bullets-color-dark .tp-bullet.selected {
  background: #212529;
}

html .slide-overlay-light .slotholder:after {
  background-color: #FFF !important;
}

html .slider-container .tp-caption-overlay-light {
  background: #FFF;
}

html .slider-container .tp-caption-overlay-opacity-light {
  background: rgba(255, 255, 255, 0.4);
}

html .tp-bullets.bullets-color-light .tp-bullet:hover,
html .tp-bullets.bullets-color-light .tp-bullet.selected {
  background: #FFF;
}

html .slide-overlay.slide-overlay-gradient .slotholder:after {
  background-color: #8a3636 !important;
  background-image: linear-gradient(to right, #8a3636 0%, #383f48 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a3636', endColorstr='#383f48', GradientType=1);
}

.tparrows.tparrows-carousel.tp-leftarrow,
.tparrows.tparrows-carousel.tp-rightarrow {
  background-color: #151515;
  border-color: #151515 #151515 #101010;
  color: #fff;
}

.tparrows.tparrows-carousel.tp-leftarrow:hover,
.tparrows.tparrows-carousel.tp-rightarrow:hover,
.tparrows.tparrows-carousel.tp-leftarrow.hover,
.tparrows.tparrows-carousel.tp-rightarrow.hover {
  border-color: #444444 #444444 #151515;
  color: #fff;
}

.tparrows.tparrows-carousel.tp-leftarrow:hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.hover:not(.bg-transparent) {
  background-color: #3d3d3d;
}

.tparrows.tparrows-carousel.tp-leftarrow:focus,
.tparrows.tparrows-carousel.tp-rightarrow:focus,
.tparrows.tparrows-carousel.tp-leftarrow.focus,
.tparrows.tparrows-carousel.tp-rightarrow.focus {
  border-color: #101010 #101010 #000000;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(42, 42, 42, 0.5);
}

.tparrows.tparrows-carousel.tp-leftarrow:focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.focus:not(.bg-transparent) {
  background-color: #171717;
}

.tparrows.tparrows-carousel.tp-leftarrow.disabled,
.tparrows.tparrows-carousel.tp-rightarrow.disabled,
.tparrows.tparrows-carousel.tp-leftarrow:disabled,
.tparrows.tparrows-carousel.tp-rightarrow:disabled {
  border-color: #151515 #151515 #101010;
}

.tparrows.tparrows-carousel.tp-leftarrow.disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow:disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:disabled:not(.bg-transparent) {
  background-color: #151515;
}

.tparrows.tparrows-carousel.tp-leftarrow:active,
.tparrows.tparrows-carousel.tp-rightarrow:active,
.tparrows.tparrows-carousel.tp-leftarrow.active,
.tparrows.tparrows-carousel.tp-rightarrow.active {
  border-color: #101010 #101010 #000000 !important;
}

.tparrows.tparrows-carousel.tp-leftarrow:active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.active:not(.bg-transparent) {
  background-color: #171717 !important;
  background-image: none !important;
}

.tparrows.arrows-primary:before {
  color: #151515;
}

.dropdown-menu.dropdown-menu-arrow-centered a:focus,
.dropdown-menu.dropdown-menu-arrow-centered a:active {
  background-color: #151515;
}

#footer .footer-ribbon {
  background: #151515;
}

#footer .footer-ribbon:before {
  border-right-color: #040404;
  border-left-color: #040404;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active {
  color: #151515 !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active:before {
  background: #151515 !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a {
  color: #151515 !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a:before {
  background: #151515 !important;
}

.scrollable.colored-slider .scrollable-slider {
  background: #151515;
}

.sample-icon-list .sample-icon a:hover {
  background-color: #151515;
}

.cursor-inner {
  background-color: #151515;
}

.cursor-inner.cursor-inner-plus:before {
  color: #151515;
}

.cursor-outer {
  border-color: #151515;
}

.cssload-thecube .cssload-cube:before {
  background-color: #151515;
}

.cssload-cube-progress {
  border-color: #151515;
}

.cssload-cube-progress-inner {
  background-color: #151515;
}

.cssload-float-rings-inner.cssload-one {
  border-bottom-color: #151515;
}

.cssload-float-rings-inner.cssload-two {
  border-right-color: #151515;
}

.cssload-float-rings-inner.cssload-three {
  border-top-color: #151515;
}

.cssload-float-bars-loading,
.cssload-float-bars-loading:after,
.cssload-float-bars-loading:before {
  background: #151515;
}

.cssload-speeding-wheel {
  border-top-color: #151515;
  border-bottom-color: #151515;
}

.cssload-zenith {
  box-shadow: 3px 3px 1px #151515;
}

.cssload-spinning-square-loading {
  background: #151515;
}

.cssload-spinning-square-loading:before {
  background: #e4002c;
}

.cssload-spinning-square-loading:after {
  background: #FFF;
}

.cssload-pulse-loader {
  background: #151515;
}

.map-rounded-pin {
  background: #151515;
}

html.boxed .body {
  border-top-color: #151515;
}

.custom-btn-style-1:before {
  border-color: #151515 !important;
}

.custom-btn-style-1.btn-light:before {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.custom-stroke-text-effect-1 {
  -webkit-text-stroke-color: #151515;
}

/*
* Card
*/
.custom-card-style-1:hover {
  background-color: #151515 !important;
}

.custom-card-style-1:hover h2,
.custom-card-style-1:hover p {
  color: #FFF !important;
}

.custom-card-style-1:hover .animated-icon svg path,
.custom-card-style-1:hover .animated-icon svg rect {
  fill: #FFF !important;
}

/*
* Owl Carousel
*/
.custom-dots-style-1 .owl-dots .owl-dot span {
  border-color: #151515 !important;
}

.custom-dots-style-1 .owl-dots .owl-dot:hover span,
.custom-dots-style-1 .owl-dots .owl-dot.active span {
  background: #151515 !important;
}

/*
* Sort Navigation
*/
.custom-nav-filter > li.active > a {
  color: #151515 !important;
  border-bottom-color: #151515 !important;
}

/*
* Link Effect
*/
.custom-link-effect-1:after {
  border-bottom-color: #151515;
}

@include media-breakpoint-up(lg) {
  .position-relative-lg {
    position: relative
  }
}

:root {
  --video-width: 100vw;
  --video-height: 100vh;
}

@media (min-aspect-ratio: 16/9) {
  :root {
    --video-height: 56.25vw;
  }
}

@media (max-aspect-ratio: 16/9) {
  :root {
    --video-width: 177.78vh;
  }
}

.video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

.subpage-video {
  margin: 50px 0;
  position: relative;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.subpage-video .overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
  color: white;
  padding: 10px;
}

.subpage-video .overlay-text:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #212529;
  z-index: -1;
  opacity: 0.5;
}

.gallery-video-wrapper .video {
  position: relative;
  height: auto;
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;
}

.gallery-video-wrapper .video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
}
